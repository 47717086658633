import React from "react";
import { ArrowCard } from "../../../../utilities";
import wave from "../../../../assets/images/Business/wave.svg";
import hgEnt from "../../../../assets/images/ownCompanies/hg_ent.png";
import vishalTradeLink from "../../../../assets/images/ownCompanies/vishal_tradelink.png";
import everest_int from "../../../../assets/images/ownCompanies/sei.png";

const Companies = () => {
  return (
    <div
      className="-mt-80 lg:mt-0 lg:pb-0 pb-8"
      style={window.innerWidth > 1440 ? { marginTop: "4rem" } : {}}
    >
      <img
        src={wave}
        alt="wave"
        className="min-w-full relative z-10"
        style={{ marginTop: "-29%" }}
      />
      <div
        className="my-24 relative z-10 space-y-24"
        style={
          window.innerWidth > 1440
            ? { marginTop: "-28%" }
            : { marginTop: "-20%" }
        }
      >
        <ArrowCard
          right={false}
          title="H.G. ENT."
          description="H.G. Ent. deals with a wide range of construction products like cutting-edge machines, and equipment for applications of all scales. It offers sales and comprehensive after-service for concrete batching, hydro power, road and heavy equipment, construction chemicals, and agricultural products."
          link="/business/trading/hg_ent"
          img={hgEnt}
        />
        <ArrowCard
          title="Vishal Trade Links"
          right={true}
          description="Vishal Trade Links provides wholesale distribution and service of on-site backup and mobile power generation equipment. Authorized dealer of Kirloskar gensets in Nepal, supplying small and large-scale operations with power solutions and knowledgeable technical support and service."
          link="/business/trading/vishal_tradelink"
          img={vishalTradeLink}
        />
        <ArrowCard
          title="Saurav Everest International"
          right={false}
          description="Saurav Everest International serves industries with supply of industrial equipment, Spare parts, Services and Solutions."
          link="/business/trading/saurav_international"
          img={everest_int}
        />
      </div>
    </div>
  );
};

export default Companies;
