import React from "react";
import { ArrowCard } from "../../../../utilities";
import wave from "../../../../assets/images/Business/singlepage_wave.svg";

// Companies icons
import sika from "../../../../assets/images/associateCompanies/sikka.png";
import chicagoPneumatic from "../../../../assets/images/associateCompanies/chicago.png";
import maruti from "../../../../assets/images/associateCompanies/maruti.png";
// import schwingStetter from "../../../../assets/images/associateCompanies/schwing-stetter.png";
// import topall from "../../../../assets/images/associateCompanies/topall.png";
// import koel from "../../../../assets/images/associateCompanies/koel.png";
import aimil from "../../../../assets/images/associateCompanies/aimil.png";
// import dpPumps from "../../../../assets/images/associateCompanies/dp-pumps.png";
import Hoppt from "../../../../assets/images/associateCompanies/hoppt.png";
// import karam from "../../../../assets/images/associateCompanies/karam.png";
import stewols from "../../../../assets/images/associateCompanies/stewols.png";
import anand from "../../../../assets/images/associateCompanies/anand.png";
import everestEqp from "../../../../assets/images/associateCompanies/everest_eqp.png";
import jacon from "../../../../assets/images/associateCompanies/Jacon-logo.png";
import sandvik from "../../../../assets/images/associateCompanies/sandvik.png";
import owens from "../../../../assets/images/associateCompanies/owens.png";
import comans from "../../../../assets/images/associateCompanies/comansa-logo.png";
import chowgule from "../../../../assets/images/associateCompanies/chowgule.png";
import Cruxweld from "../../../../assets/images/associateCompanies/Cruxweld.png";
import SafetyJogger from "../../../../assets/images/associateCompanies/SafetyJogger.png";

const Associates = () => {
  return (
    <div className="-mt-80 lg:mt-0 pb-8">
      <img
        src={wave}
        alt="wave"
        className="min-w-full relative z-10"
        style={{ marginTop: "-26%" }}
      />
      <div
        className="my-24 relative z-10 space-y-24"
        style={{ marginTop: "-20%" }}
      >
        <ArrowCard
          right={false}
          title="Sika"
          description="Sika is a specialty chemicals company with a leading position in the development and production of systems and products for bonding, sealing, damping, reinforcing and protection in the building sector."
          link="https://www.sika.com/en/home.html"
          img={sika}
        />
        <ArrowCard
          title="Chicago Pneumatic"
          right={true}
          description="Chicago Pneumatic represents tools and compressors that are engineered for high performance. Some of ts products are Air Compressor, Rock Drills, Hydruic Breakers, Hydraulic Compactor, etc."
          link="https://www.cp.com/en"
          img={chicagoPneumatic}
        />
        {/* <ArrowCard
          title="Schwing Stetter"
          right={false}
          description="We offer a wide range of products and some of them are: Concrete Equipment, Batching Plant, Concrete Pump, Transit Mixer, Boom Pump, Robotic Shorcrete Machines etc."
          link="https://www.schwingstetterindia.com/"
          img={schwingStetter}
        /> */}
        {/* <ArrowCard
          right={true}
          title="Topall"
          description="Our Products: Light Concrete and Tunneling Equipment Concrete Mixer, Mini Dumper, Mucking Loader, Shot Create Machine, Grouting Pump, Ventilation Fan, Duct Pipes, Drill Rod, Drill Bits etc."
          img={topall}
          link="https://www.topallimpex.in/"
        /> */}
        {/* <ArrowCard
          right={false}
          title="Koel Green"
          description="KOEL has consistently delivered reliable and high quality products including telecom, construction, IT / ITES, retail, hotels, hospitals, banking and finance and manufacturing at very competitive costs."
          img={koel}
          link="https://koel.kirloskar.com/"
        /> */}
        <ArrowCard
          right={false}
          title="Aimil Ltd."
          description="Our Products: Consturction Material Testing Equipments: Compression Testing Machine, cube Mould, Slump Cone, Core Cutter, Sand Pouring Cylinder, Concrete lab mixer, etc."
          img={aimil}
          link="https://www.aimil.com/"
        />
        {/* <ArrowCard
          right={false}
          title="Dp Pumps"
          description="Our Products: Vertical Inline Stainless Steel Centrifugal Pump, Hydro Penumatic / pressure Booster Systems, Sewage and Waste Water Pumps, Deep Bore Well Pump,  Hydro Booster Cube, HVAC Pumps etc."
          img={dpPumps}
          link="https://www.dp-pumps.com/"
        /> */}
        <ArrowCard
          right={true}
          title="Hoppt"
          description="Our Products: Road and Soil Compaction Equipments: Hand Roller, Tamping Rammer, Earth Compactor, Concrete Cutter, Power Trowel etc."
          img={Hoppt}
          link="https://www.hoppt.com/"
        />
        {/* <ArrowCard
          right={false}
          title="Karam"
          description="Our Products: Safely Equipments for Construction Purpose: Helmets, Safety Belt, Safety Shoe, Gumboot, Safety Net, Air Protector, Safety Spectacles, Safety Rope for access, etc."
          img={karam}
          link="https://karam.in/"
        /> */}
        <ArrowCard
          right={false}
          title="Stewols"
          description="Stewols is largest manufacturers and exporters of high quality Steel Some of its products are Steel Fiber for Tunneling Concrete and Industrial Flooring. Shaktiman steel is their registered TM."
          img={stewols}
          link="https://www.stewols.com/"
        />
        <ArrowCard
          right={true}
          title="Anand Mincons"
          description="We manufacture flexible lay flat tubing, semirigid flexible tubing and its accessories. Some of our products are Flexible Ventilation Ducts, Ventilation Fan, Coupling and Accessories."
          img={anand}
          link="https://www.anandmincons.com/"
        />
        <ArrowCard
          right={false}
          title="Everest"
          description="'EVEREST' Brand SILICA FUME specialises in superior quality cement sheets, Designer Cement Sheets, heavy duty wall boards, and solid wall panels."
          link="#"
          img={everestEqp}
        />
        <ArrowCard
          right={true}
          title="Maruti"
          description="Maruti is engaged in such as shuttering formwork, falsework, scaffolding. Our products are: PVC Water Stops, Bridge Bearing Pads, Pneumatic Air House Pipes, PVC Sheets."
          link="#"
          img={maruti}
        />
        <ArrowCard
          right={false}
          title="Jacon"
          description="Robotic Shotcreating Machine."
          link="#"
          img={jacon}
        />
        <ArrowCard
          right={true}
          title="Sandvik"
          description="Small hole drilling, Threaded button bills, Drill rods, Shank adapters, Coupling sleeves."
          link="#"
          img={sandvik}
        />
        <ArrowCard
          right={false}
          title="Owens Corning"
          description="Insulation, Roofing, Fiberglass, Composites, Geomembrane, and related materials and products."
          link="#"
          img={owens}
        />
        <ArrowCard
          right={true}
          title="Comansa"
          description="Insulation, Roofing, Fiberglass, Composites, Geomembrane, and related materials and products."
          link="#"
          img={comans}
        />
        <ArrowCard
          right={false}
          title="Chowgule"
          description="360 degree truck mounted cranes ranging from 3-7 ton."
          link="#"
          img={chowgule}
        />
        <ArrowCard
          right={true}
          title="Cruxweld"
          description="Welding machine, Air plasma cutting machine."
          link="#"
          img={Cruxweld}
        />
        <ArrowCard
          right={false}
          title="Safety Joggers"
          description="A safety brand engineered on europe, providing complete range of safety products, gumboots, safety shoes, helmets, gloves etc."
          link="#"
          img={SafetyJogger}
        />
      </div>
    </div>
  );
};

export default Associates;
