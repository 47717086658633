import React from "react";
import banner from "../../../../assets/images/Business/banner_background.png";
import machine from "../../../../assets/images/Business/machineIcon.svg";
import manufacturing from "../../../../assets/images/Business/manufacturing.png";

const Banner = () => {
  return (
    <div
      className="bg-no-repeat bg-cover h-full relative"
      style={
        window.innerWidth < 1440
          ? {
              backgroundImage: `url(${banner})`,
              transform: "translateY(-9.1rem)",
            }
          : {
              backgroundImage: `url(${banner})`,
              transform: "translateY(-9.1rem)",
              paddingBottom: "4rem",
            }
      }
    >
      <div
        className="container mx-auto grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2"
        style={{
          paddingTop: "11.8rem",
          paddingBottom: "13.8rem",
        }}
      >
        <div className="pt-10">
          <div className="flex lg:space-x-10 space-x-2 items-center">
            <img src={machine} alt="" className="h-28 w-28" />
            <div className="flex flex-col justify-center space-y-3">
              <p className="font-primary text-2xl font-bold uppercase text-white">
                tater's group
              </p>
              <p className="font-primary lg:text-4xl text-2xl font-bold uppercase text-white">
                Manufacturing
              </p>
            </div>
          </div>
          <p className="font-primary text-lg font-normal pt-2 text-white">
            Tater Group is manufacturing various construction materials
            corresponding to the customer’s needs and specifications. It is an
            ISO and NS certified manufacturer of cables, conductors, casings,
            ducting, uPVC and HDPE pipes. State-of-the-art manufacturing and
            testing facilities provide the backbone for proprietary brands,
            including Ajanta Vishal Cables and Supremo Ducting. The companies
            involved in manufacturing are as follows.
          </p>
        </div>
      </div>
      <img
        src={manufacturing}
        alt=""
        className="absolute top-14 right-0 lg:block hidden"
      />
    </div>
  );
};

export default Banner;
