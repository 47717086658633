import React, { useEffect, useState } from "react";
import { TopBar, Navbar, Footer, BottomBar } from "../../components";
import { useLocation } from "react-router-dom";
interface Layout {
  children: any;
}
const RootLayout = ({ children }: Layout) => {
  const [currentPathname, setCurrentPathname] = useState("");
  // Sets window to initial position
  const { pathname } = useLocation();
  useEffect(() => {
    setCurrentPathname(() => pathname);
    if (pathname !== currentPathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname, currentPathname]);
  return (
    <>
      <TopBar />
      <Navbar />
      {children}
      <Footer />
      <BottomBar />
    </>
  );
};

export default RootLayout;
