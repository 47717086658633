// @ts-nocheck
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAlert } from "../../../Redux";
import "./alert.css";
import AlertComponent from "./AlertComponent";

const Alert = ({ loading, getAlert, popupAlert }) => {
  useEffect(() => {
    getAlert();
  }, [getAlert]);

  return (
    <>
      <div>
        {popupAlert.map((alert, index) => {
          return (
            <div key={index}>
              {alert?.acf?.publish ? <AlertComponent alert={alert} /> : null}
            </div>
          );
        })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  popupAlert: state.alertList.alert,
  loading: state.alertList.loading,
});

export default connect(mapStateToProps, { getAlert })(Alert);
