import React from "react";
import { Link } from "react-router-dom";
import circle from "../../../assets/images/company_card_circle.svg";
interface companyProps {
  id: number;
  icon: string;
  title: string;
  link: string;
}
const CompanyCard = ({ id, link, icon, title }: companyProps) => {
  return (
    <Link
      to={link}
      className={`h-36 bg-white hover:text-white bg-gradient-to-br hover:from-red-400 hover:to-yellow-400 bg-no-repeat rounded-2xl shadow-xl py-3 px-4 flex flex-col justify-evenly transition-all ease-in-out duration-300 transform hover:-translate-y-2 hover:shadow-2xl cursor-pointer relative`}
      style={
        window.innerWidth <= 1024
          ? { width: "14.75rem" }
          : { width: "18.75rem" }
      }
    >
      <img
        src={circle}
        alt="circle"
        className="absolute right-0 pr-5 bottom-0 pb-5"
      />
      <div
        className={
          "w-14 h-14 rounded-full bg-primary-600 bg-opacity-80 flex justify-center items-center"
        }
      >
        <img src={icon} alt="icon" className="w-9 h-9" />
      </div>
      <p className={"font-primary text-lg font-semibold"}>{title}</p>
    </Link>
  );
};

export default CompanyCard;
