import React from "react";
import { BlogLists, BlogBanner } from "../../components";

const Blogs = () => {
  return (
    <>
      <BlogBanner />
      <BlogLists />
    </>
  );
};

export default Blogs;
