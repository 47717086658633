import React from "react";
//@ts-ignore
import ModalVideo from "react-modal-video";

interface IProps {
  isOpen: boolean;
  setOpen: any;
}
const VideoModal = ({ isOpen, setOpen }: IProps) => {
  return (
    <ModalVideo
      channel="youtube"
      autoplay
      isOpen={isOpen}
      videoId="aqz-KE-bpKQ"
      onClose={() => setOpen(false)}
    />
  );
};

export default VideoModal;
