// @ts-nocheck
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getSingleProduct } from "../../../Redux/actions/Products/ProductAction";
import banner from "../../../assets/images/Business/banner_background.png";
import wave from "../../../assets/images/Business/wave.svg";

const SingleBrandPage = ({ getSingleProduct, product, loading }) => {
  const { id } = useParams();

  useEffect(() => {
    getSingleProduct({ id });
  }, [id, getSingleProduct]);

  return (
    <>
      <div className="">
        <>
          <div
            className="bg-no-repeat bg-cover w-full h-full relative"
            style={
              window.innerWidth < 1440
                ? {
                    backgroundImage: `url(${banner})`,
                    transform: "translateY(-9.1rem)",
                  }
                : {
                    backgroundImage: `url(${banner})`,
                    transform: "translateY(-9.1rem)",
                    paddingBottom: "4rem",
                  }
            }
          >
            <div
              className="container mx-auto grid lg:grid-cols-3 grid-cols-1 gap-2"
              style={{
                paddingTop: "11.8rem",
                paddingBottom: "12rem",
              }}
            >
              <div className="pt-10 col-span-2">
                <div className="flex lg:space-x-10 space-x-2 items-center">
                  <div className="flex flex-col justify-center lg:space-y-3">
                    <p className="font-primary lg:text-2xl text-xl font-bold uppercase text-white">
                      {product.acf && product.acf.represented_by}
                    </p>
                    <p className="font-primary lg:text-4xl text-3xl font-bold uppercase text-white">
                      {product.acf && product.acf.brand_name}
                    </p>
                  </div>
                </div>
                <p className="font-primary text-lg font-normal pt-2 text-white">
                  {product.acf && product?.acf.brand_description}
                </p>
              </div>
              <div className="place-self-center col-span-1">
                <img
                  src={product.acf && product?.acf?.brand_image?.url}
                  alt=""
                  className="absolute top-20 right-0 h-60 mt-24 hidden lg:block pb-2"
                />
              </div>
            </div>
          </div>
        </>
      </div>

      {/* -----  end of banner section  ------- */}

      <div
        className="-mt-80 lg:mt-0 lg:pb-0 pb-8"
        style={{ marginTop: window.innerWidth < 1440 ? "" : "4rem" }}
      >
        <img
          src={wave}
          alt="wave"
          className="min-w-full relative z-10"
          style={{ marginTop: "-29%" }}
        />
        <div
          className="my-24 relative z-10 space-y-24"
          style={{ marginTop: "-20%" }}
        >
          <div className="mx-auto container">
            {product?.acf?.products &&
              product?.acf?.products.map((item, index) => {
                return (
                  <>
                    <div className="mb-24 p-2">
                      <p className="font-semibold font-primary text-gray-700 text-3xl pb-4 ">
                        {item.key_product_name}
                      </p>
                      <div className="w-20 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
                      {item.key_product_description && (
                        <p className=" font-primary  py-2">
                          {item.key_product_description}
                        </p>
                      )}
                      <div className="mb-10">
                        {item?.key_product_image.url && (
                          <img
                            src={item?.key_product_image?.url}
                            alt=""
                            className=" h-full md:h-60 w-full md:w-96 my-6"
                          />
                        )}
                      </div>

                      <div className="">
                        {item?.sub_product && (
                          <div className="">
                            {/* <p className="py-3 mt-4 text-gray-400 text-xl font-medium">Sub Products</p> */}
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3  mt-10">
                              {item?.sub_product.map((item, index) => {
                                return (
                                  <>
                                    <a
                                      href={item?.product_link}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      <div className="bg-gray-100 pb-2 shadow-lg rounded-md overflow-hidden transform hover:-translate-y-2 cursor-pointer">
                                        <img
                                          src={item.sub_product_image.url}
                                          alt=""
                                          className="h-44   w-full object-cover"
                                        />
                                        <div className="px-2 py-4">
                                          <p className=" text-lg text-gray-700 font-semibold ">
                                            {item.sub_product_name}
                                          </p>
                                          <p className="text-sm mt-4 text-gray-400">
                                            {item.sub_product_description}
                                          </p>
                                        </div>
                                      </div>
                                    </a>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  product: state.productList.product,
  loading: state.productList.loading,
});

export default connect(mapStateToProps, { getSingleProduct })(SingleBrandPage);
