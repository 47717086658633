import React from "react";
import banner from "../../../assets/images/Blog/blog_image.png";
import blogIcon from "../../../assets/images/Blog/blogIcon.svg";

const Banner = () => {
  return (
    <div
      className="bg-no-repeat bg-cover h-full relative"
      style={{
        backgroundImage: `url(${banner})`,
        transform: "translateY(-9.1rem)",
      }}
    >
      <div
        className="container mx-auto md:grid"
        style={{
          paddingTop: "11.8rem",
          paddingBottom: "13.8rem",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <div
          style={
            window.innerWidth > 768
              ? { width: "41.5rem" }
              : { width: "20.5rem" }
          }
          className="pt-10"
        >
          <div className="flex space-x-10 items-center">
            <img src={blogIcon} alt="" className="h-20 w-20 md:h-28 md:w-28" />
            <div className="flex flex-col justify-center space-y-3">
              <p className="font-primary text-2xl font-bold uppercase text-white">
                tater's group
              </p>
              <p className="font-primary text-4xl font-bold uppercase text-white">
                Blog/news
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
