import React, { useRef } from "react";
import { TestimonialCard } from "../../../utilities";
import potrait from "../../../assets/images/person/potrait.jpg";
import arrowRight from "../../../assets/images/arrow_right.svg";
import arrowLeft from "../../../assets/images/arrow_left.svg";
// import swiper slide
import { Swiper, SwiperSlide } from "swiper/react";
// Swiper styles
import "swiper/swiper.min.css";
// Swiper css
import "./testimonial.css";
// Import swiper core and required modules
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper/core";
// install Swiper modules
SwiperCore.use([Navigation, Autoplay, Pagination]);

const TestimonialSwiper = () => {
  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);

  return (
    <div className="testimonial-container">
      <Swiper
        slidesPerView={window.innerWidth >= 1024 ? "auto" : 1}
        autoplay={window.innerWidth >= 1024 ? false : true}
        centeredSlides={false}
        spaceBetween={20}
        className="mySwiper"
        grabCursor
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
          disabledClass: "buttonDisable",
        }}
        onSwiper={(swiper) => {
          // Delay execution for the refs to be defined
          setTimeout(() => {
            // Override prevEl & nextEl now that refs are defined
            // @ts-ignore
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = navigationNextRef.current;

            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
      >
        <SwiperSlide className="pb-8">
          <TestimonialCard
            name="John Doe"
            image={potrait}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit aliquet sed pretium elit. A ligula elementum velit metus, at vivamus vel nunc. Odio magna euismod aliquet aliquam a vel,"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonialCard
            name="John Doe"
            image={potrait}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit aliquet sed pretium elit. A ligula elementum velit metus, at vivamus vel nunc. Odio magna euismod aliquet aliquam a vel,"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonialCard
            name="John Doe"
            image={potrait}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit aliquet sed pretium elit. A ligula elementum velit metus, at vivamus vel nunc. Odio magna euismod aliquet aliquam a vel,"
          />
        </SwiperSlide>
      </Swiper>
      <div className="hidden lg:flex absolute top-0 right-0 sm:left-auto space-x-10 pt-14 pr-24">
        <img
          src={arrowLeft}
          alt=""
          ref={navigationPrevRef}
          className="cursor-pointer"
        />
        <img
          src={arrowRight}
          alt=""
          ref={navigationNextRef}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default TestimonialSwiper;
