import React from "react";
import { EnergyBanner, EnergySectors } from "../../../components";
const Energy = () => {
  return (
    <>
      <EnergyBanner />
      <EnergySectors />
    </>
  );
};

export default Energy;
