import React from "react";
import wordMap from "../../../assets/images/world_map_dot.png";
import "./style.css";

const Achievements = () => {
  return (
    <div
      className="container mx-auto flex items-center flex-col bg-no-repeat bg-center mb-10"
      style={{ backgroundImage: `url(${wordMap})` }}
    >
      <p
        className="font-primary text-2xl sm:text-3xl font-semibold text-black text-center pb-10 sm:pb-16"
        style={
          window.innerWidth > 768 ? { width: "38.37rem" } : { width: "100%" }
        }
      >
        We completed 45+ successful years with remarkable achievements
      </p>
      <div className="flex flex-col sm:flex-row space-y-10 sm:space-y-0 sm:space-x-28">
        <div>
          <p className="font-primary text-3xl text-gray-500 font-semibold text-center">
            100+
          </p>
          <p className="font-primary text-sm font-semibold text-black text-center">
            Complete Projects
          </p>
        </div>
        <div>
          <p className="font-primary text-3xl text-gray-500 font-semibold text-center">
            15+
          </p>
          <p className="font-primary text-sm font-semibold text-black text-center">
            Associated Companies
          </p>
        </div>
        <div>
          <p className="font-primary text-3xl text-gray-500 font-semibold text-center">
            50+
          </p>
          <p className="font-primary text-sm font-semibold text-black text-center">
            Partners
          </p>
        </div>
        <div>
          <p className="font-primary text-3xl text-gray-500 font-semibold text-center">
            8+
          </p>
          <p className="font-primary text-sm font-semibold text-black text-center">
            Branches over Nepal
          </p>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
