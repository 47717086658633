import React from "react";
import banner from "../../../../assets/images/Business/banner_background.png";
import bankingImage from "../../../../assets/images/Business/banking.png";
import bankIcon from "../../../../assets/images/Business/Bank.svg";

const Banner = () => {
  return (
    <div
      className="bg-no-repeat bg-cover h-full relative"
      style={
        window.innerWidth < 1440
          ? {
              backgroundImage: `url(${banner})`,
              transform: "translateY(-9.1rem)",
            }
          : {
              backgroundImage: `url(${banner})`,
              transform: "translateY(-9.1rem)",
              paddingBottom: "4rem",
            }
      }
    >
      <div
        className="container mx-auto grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2"
        style={{
          paddingTop: "11.8rem",
          paddingBottom: "13.8rem",
        }}
      >
        <div className="pt-10">
          <div className="flex space-x-10 items-center">
            <img src={bankIcon} alt="" className="h-28 w-28" />
            <div className="flex flex-col justify-center space-y-3">
              <p className="font-primary text-2xl font-bold uppercase text-white">
                tater's group
              </p>
              <p className="font-primary text-4xl font-bold uppercase text-white">
                Banking
              </p>
            </div>
          </div>
          <p className="font-primary text-lg font-normal pt-2 text-white">
            Tater Group is partnered with different companies and business firms
            to address complex challenges and mobilise resources. Our partners
            have been contributing ideas and support for our growth and
            development which is not limited to financial help.
          </p>
        </div>
      </div>
      <img
        src={bankingImage}
        alt=""
        className="absolute top-36 right-0 lg:block hidden"
      />
    </div>
  );
};

export default Banner;
