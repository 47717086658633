import React from "react";
import moment from "moment";
const BottomBar = () => {
  return (
    <div className="container mx-auto text-center sm:text-left flex sm:flex-row justify-center items-center py-2">
      <p>
        &copy;{moment(Date.now()).format("YYYY")} Tater Group || Powered by{" "}
        <a
          href="https://dakshyanepal.com"
          className="no-underline text-blue-400 font-bold font-primary hover:underline"
        >
          Dakshya Nepal
        </a>
      </p>
    </div>
  );
};

export default BottomBar;
