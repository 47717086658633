import React from 'react'
import Map from '../../components/Contact/Map'
import ContactForm from '../../components/Contact/ContactForm'

const Contact = () => {
    return (
        <div>
            <Map />
            <ContactForm />
        </div>
    )
}

export default Contact
