/* eslint-disable import/no-anonymous-default-export */
import { GET_ALERT, ALERT_ERROR } from "../../types";
const initialState = {
  loading: true,
  alert: [],
  error: "",
};

interface AlertReducerProps {
  type: string;
  payload: string;
}

export default (state = initialState, { type, payload }: AlertReducerProps) => {
  switch (type) {
    case GET_ALERT:
      return { ...state, alert: payload, loading: false };
    case ALERT_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
