import React from "react";
import { ArrowCard } from "../../../../utilities";
import wave from "../../../../assets/images/Business/wave.svg";
import ridiLogo from "../../../../assets/images/ownCompanies/ridi.png";
import rairangLogo from "../../../../assets/images/ownCompanies/rairang-hydropower.png";
import avhLogo from "../../../../assets/images/ownCompanies/avh.png";

const Sectors = () => {
  return (
    <div
      className="-mt-80 lg:mt-0 pb-8"
      style={window.innerWidth > 1440 ? { marginTop: "4rem" } : {}}
    >
      <img
        src={wave}
        alt="wave"
        className="min-w-full relative z-10"
        style={{ marginTop: "-29%" }}
      />
      <div
        className="my-24 relative z-10 space-y-24"
        style={
          window.innerWidth > 1440
            ? { marginTop: "-28%" }
            : { marginTop: "-20%" }
        }
      >
        <ArrowCard
          right={false}
          title="Ridi Hydropower"
          description="Ridi Hydropower Development Company is a limited company registered under the Company Act of the Government of Nepal. It is established in 2000 AD with a mission to develop, own and operate hydroelectric project."
          link="https://ridihydro.com.np"
          img={ridiLogo}
        />
        <ArrowCard
          right={true}
          title="Rairang Hydropower"
          description="Rairang Hydropower Development Company is a mainstream participant in the growing Nepal’s energy industry. The company and its associates are emerging as market leading owner and operator of hydro-energy projects in Nepal."
          link="https://rairanghydro.com.np/"
          img={rairangLogo}
        />
        <ArrowCard
          right={false}
          title="Arun Kabeli Power Limited"
          description="After the completion of Piluwakhola Hydropower Project in 2001, Arun Valley Hydropower Development Company Limited started feasibility study of Ridi Hydropower project (2400 kW) in Gulmi district."
          link="https://arunkabeli.com/"
          img={avhLogo}
        />
      </div>
    </div>
  );
};

export default Sectors;
