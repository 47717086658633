import React from "react";
import { AboutCard } from "../../../utilities";
import icon1 from "../../../assets/images/about/icon1.png";
import icon2 from "../../../assets/images/about/icon2.png";
import icon3 from "../../../assets/images/about/icon3.png";

const CoreValues = () => {
  return (
    <div className="mt-10 lg:mt-36 container mx-auto">
      <div className="flex flex-col justify-center items-center">
        <p className="font-semibold font-primary text-2xl lg:text-3xl text-gray-800 mb-5">
          CORE VALUES & POLICIES
        </p>
        <p
          className="font-primary text-center text-base lg:text-xl text-gray-500 font-normal"
          style={{ width: window.innerWidth > 1024 ? "46.62rem" : "100%" }}
        >
          These core values that we stand for are integrated into terms and
          policies. They influence our business strategies and define our
          purpose of being. We not only live them, but envision a future that is
          built on their foundation.
        </p>
      </div>
      <div className="flex flex-col items-center lg:grid lg:grid-cols-3 lg:gap-10 pt-10 space-y-5 lg:space-y-0 lg:pt-14">
        <AboutCard
          icon={icon1}
          title="EXPANDING OUR HORIZON"
          description="We strive to benefit the localities in which we operate and contribute to Nepal's development through involvement in projects such as Arun Valley and Rhidi hydro power facilities. Our trading and manufacturing units have evolved to become one of the most successful ones. We are always keen about new ideas be it about business expansion or a project involving risk."
        />
        <AboutCard
          icon={icon2}
          title="HIGH DEMAND ACROSS THE COUNTRY"
          description="We have been serving industrial sectors effectively through our group of highly specialized subsidiary companies. Quality is our flagship and we have never failed to entertain our customers with its experience. The effort and value that we put in every project has enabled us to touch many lives and earn their trust."
        />
        <AboutCard
          icon={icon3}
          title="PORTFOLIO IN THE EDUCATION & BANKING SECTORS"
          description="Tater group provides employment to over 200 individuals across its wide array of operations. Our most recent additions to our business portfolio in education and banking sectors. Similarly, investments in NCIT College and Sunrise Bank are the testament to the working principle-- expanding our horizon."
        />
      </div>
    </div>
  );
};

export default CoreValues;
