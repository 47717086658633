// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Link as LinkS } from "react-scroll";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { connect } from "react-redux";
import { getVacancies } from "../../../Redux/actions/Vacancies/VacanciesAction";
import "./accordion.css";
import DropDownCircle from "../../../assets/images/career/DropDownCircle_bg.svg";

import {
  Accordion as AccordionComponent,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Link } from "react-router-dom";

const Accordion = ({ getVacancies, vacancies, loading }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    getVacancies();
  }, [getVacancies]);

  return (
    <AccordionComponent
      className="relative"
      allowZeroExpanded={true}
      onClick={() => setActive(!active)}
    >
      {vacancies.map((vacancy, i) => (
        <AccordionItem
          className={`shadow-half rounded-md mt-5 ${
            active ? "bg-skin-400" : "bg-white"
          } transition-all ease-linear duration-300`}
          key={i}
        >
          <AccordionItemHeading>
            <AccordionItemButton className="flex items-center justify-between py-3 md:py-3 px-4 text-xl md:text-2xl heading">
              <div className="title font-primary font-semibold text-lg">
                {vacancy.acf.title}
              </div>
              <div className="arrow">
                {" "}
                {active ? (
                  <ChevronUpIcon className="h-10 w-10 text-black" />
                ) : (
                  <ChevronDownIcon className="h-10 w-10 text-black" />
                )}
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className=" tab-content overflow-hidden bg-gray-50 leading-normal">
              <div className=" flex flex-col p-8">
                <img
                  src={DropDownCircle}
                  alt=""
                  className="absolute opacity-30 bottom-48 right-3"
                />
                <p className="text-lg py-2">
                  <span className="font-medium">Education: </span>
                  <span className="text-lg">{vacancy.acf.education} </span>
                </p>
                <p className="text-lg py-2">
                  <span className="font-medium">Experience: </span>
                  <span className="text-lg">{vacancy.acf.experience} </span>
                </p>
                <p className="text-lg py-2">
                  <span className="font-medium">Location: </span>
                  <span className="text-lg">{vacancy.acf.location} </span>
                </p>
                <p className="text-lg py-2">
                  <span className="font-medium">Salary: </span>
                  <span className="text-lg">{vacancy.acf.salary} </span>
                </p>
                <p className="text-lg font-medium py-2">Job Description: </p>
                <div
                  className="flex flex-col list-disc ml-6 font-light"
                  dangerouslySetInnerHTML={{
                    __html: vacancy.acf.job_specification,
                  }}
                ></div>
                <p className="text-lg py-2">
                  <span className="text-lg">
                    {vacancy?.acf?.job_description}
                  </span>
                </p>
                {/* <LinkS
                  to="career_contact"
                  spy={true}
                  smooth={true}
                  offset={-300}
                  className="py-4 px-4 flex justify-center items-center mt-5 rounded-full bg-gradient-to-r from-gradient-1 to-gradient-2 text-white font-primary text-sm font-semibold shadow-md transition-all ease-in-out duration-300 hover:shadow-xl transform hover:-translate-y-1"
                  style={{ width: "8rem", height: "3.8rem" }}
                >
                  Apply Now
                </LinkS> */}
                <Link
                  to={{
                    pathname:
                      "https://docs.google.com/forms/d/1fDuRzSOZoywdhkFhbupiz86m3XA-p9uPqd7-zFQklL0",
                  }}
                  target={"_blank"}
                  relative="path"
                  className="py-4 px-4 flex justify-center items-center mt-5 rounded-full bg-gradient-to-r from-gradient-1 to-gradient-2 text-white font-primary text-sm font-semibold shadow-md transition-all ease-in-out duration-300 hover:shadow-xl transform hover:-translate-y-1"
                  style={{ width: "8rem", height: "3.8rem" }}
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </AccordionComponent>
  );
};

const mapStateToProps = (state) => ({
  vacancies: state.VacanciesList.vacancies,
  loading: state.VacanciesList.loading,
});

export default connect(mapStateToProps, { getVacancies })(Accordion);
