import React from "react";
import Sei from "../../../../assets/images/ownCompanies/sei.png";
import singleBanner from "../../../../assets/images/Business/singleBusinessBanner.png";

const Banner = () => {
  return (
    <div
      className="bg-no-repeat bg-cover h-full"
      style={{
        backgroundImage: `url(${singleBanner})`,
        transform: "translateY(-9.1rem)",
      }}
    >
      <div
        className="container mx-auto grid grid-cols-1 lg:grid-cols-2"
        style={{
          paddingTop: "11.8rem",
          paddingBottom: "13.8rem",
        }}
      >
        <div  className="pt-10">
          <div className="flex  items-center">
            <div className="flex flex-col justify-center space-y-3">
              <p className="font-primary lg:text-5xl text-2xl  font-bold uppercase text-gray-700">
                Saurav Everest international
              </p>
            </div>
          </div>
          <p className="font-primary text-lg font-normal pt-2 text-gray-800">
            S.E.I is dedicated to serve industries with Supply of industrial
            equipment, Spare parts, Services and Solutions- 4S. Team of highly
            experience engineers & managers, serving from 5 location currently.
            We are committed to quality service and supply.
          </p>
        </div>
        <div className="place-self-center">
          <img src={Sei} alt="" className="lg:block hidden" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
