import React from "react";
import network_bg from "../../../assets/images/about/network_bg.png";
import about_network from "../../../assets/images/about/about_network.png";

const Network = () => {
  return (
    <div
      className="bg-no-repeat bg-cover network mb-32 lg:mb-0"
      style={{
        backgroundImage: `url(${network_bg})`,
      }}
    >
      <div className="mx-auto container mt-5 lg:mt-24 flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center">
          <p className="text-lg font-primary font-semibold capitalize text-red-400 pb-4">
            Tater Group's
          </p>
          <p className="text-2xl lg:text-4xl font-primary font-semibold capitalize text-black pb-4">
            Networks
          </p>
          <div className="w-36 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
          <p className="font-primary leading-7 lg:text-justify text-black pb-8">
            Tater Group’s distribution activity revolves around the group’s
            partnership with some of the world’s most reputed companies. Most of
            the products of these companies are distributed and supplied all
            across Nepal through a comprehensive network of distributors and
            dealers so as to reach every possible consumer segment. The Group
            also works closely with the international companies in designing
            advertisements and promotional campaign to ensure the growth of
            their brands.
          </p>
          <img src={about_network} alt="Location" />
        </div>
      </div>
    </div>
  );
};

export default Network;
