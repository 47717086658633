// @ts-nocheck
import React from 'react'

const ProductSkeleton = () => {
    return (
        <div className="space-x-2 my-10 mb-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 gap-y-5 items-center" style={{marginBottom:'5rem'}}>

      <div className="border border-gray-200 shadow rounded-md  max-w-sm w-full mx-auto h-65">
        <div className=" animate-pulse bg-gray-100 h-44 w-full"/>
        <div className="animate-pulse flex flex-col space-x-4">
          <div className="flex-1 p-4 space-y-4 mt-4 py-1">
            <div className="h-8 bg-white rounded w-full"/>
          </div>
        </div>
      </div>

      <div className="border border-gray-200 shadow rounded-md  max-w-sm w-full mx-auto h-65">
        <div className=" animate-pulse bg-gray-100 h-44 w-full"/>
        <div className="animate-pulse flex flex-col space-x-4">
          <div className="flex-1 p-4 space-y-4 mt-4 py-1">
            <div className="h-8 bg-white rounded w-full"/>
          </div>
        </div>
      </div>
      
      <div className="border border-gray-200 shadow rounded-md  max-w-sm w-full mx-auto h-65">
        <div className=" animate-pulse bg-gray-100 h-44 w-full"/>
        <div className="animate-pulse flex flex-col space-x-4">
          <div className="flex-1 p-4 space-y-4 mt-4 py-1">
            <div className="h-8 bg-white rounded w-full"/>
          </div>
        </div>
      </div>

      {/* --------- */}
      <div className="border border-gray-200 shadow rounded-md  max-w-sm w-full mx-auto h-65">
        <div className=" animate-pulse bg-gray-100 h-44 w-full"/>
        <div className="animate-pulse flex flex-col space-x-4">
          <div className="flex-1 p-4 space-y-4 mt-4 py-1">
            <div className="h-8 bg-white rounded w-full"/>
          </div>
        </div>
      </div>

      <div className="border border-gray-200 shadow rounded-md  max-w-sm w-full mx-auto h-65">
        <div className=" animate-pulse bg-gray-100 h-44 w-full"/>
        <div className="animate-pulse flex flex-col space-x-4">
          <div className="flex-1 p-4 space-y-4 mt-4 py-1">
            <div className="h-8 bg-white rounded w-full"/>
          </div>
        </div>
      </div>
      
      <div className="border border-gray-200 shadow rounded-md  max-w-sm w-full mx-auto h-65">
        <div className=" animate-pulse bg-gray-100 h-44 w-full"/>
        <div className="animate-pulse flex flex-col space-x-4">
          <div className="flex-1 p-4 space-y-4 mt-4 py-1">
            <div className="h-8 bg-white rounded w-full"/>
          </div>
        </div>
      </div>
      {/* -------- */}

    </div>
    )
}

export default ProductSkeleton
