import React from "react";
import logoTater from "../../../assets/images/tater_white.png";
import footerBg from "../../../assets/images/Footer_bg.svg";
import facebook from "../../../assets/images/icons/facebook.svg";
import instagram from "../../../assets/images/icons/instagram.svg";

import { InvertButton } from "../../Buttons";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${footerBg})`,
        backgroundPositionX: window.innerWidth < 768 ? "-30rem" : "",
        marginTop: window.innerWidth < 1440 ? "" : "7rem",
      }}
      className="bg-no-repeat bg-cover sm:bg-cover"
    >
      <div
        className="container mx-auto block lg:grid pt-44 -mt-36 lg:mt-0 pb-8"
        style={{ gridTemplateRows: ".5fr 1fr" }}
      >
        <div className="flex flex-col md:flex-row justify-between">
          <div>
            <p className="font-primary text-2xl lg:text-3xl font-semibold text-white">
              Get In Touch With Us
            </p>
            <p className="font-primary text-sm text-white pt-4">
              Contact Us or Send Us a Feedback Here
            </p>
          </div>
          <div className="mt-5 xl:mt-16">
            <InvertButton text="Contact Us" />
          </div>
        </div>
        <div
          className="flex flex-col lg:grid pt-14"
          style={{ gridTemplateColumns: "1fr .6fr .5fr" }}
        >
          <div className="flex flex-col space-y-3 lg:space-y-1">
            <img
              src={logoTater}
              alt="tater"
              style={{ width: "9.12rem", height: "9.12rem" }}
            />
            <p
              className="font-primary text-2xl lg:text-3xl font-semibold uppercase text-white"
              style={
                window.innerWidth > 768
                  ? { width: "23.93rem" }
                  : { width: "100%" }
              }
            >
              tater group
            </p>
            <p className="font-primary text-sm text-white">
              Hotline Number: +977 9801000095
            </p>
            <p className="font-primary text-sm text-white">
              Phone: +977-01-5100374 / +977-01-5100413
            </p>
            <p className="font-primary text-sm text-white">
              Email: info@tater-group.com
            </p>
            <p className="font-primary text-sm text-white">
              Balkumari bridge, Kathmandu, Nepal
            </p>
          </div>
          <div className="hidden lg:flex gap-10">
            <div className="flex flex-col space-y-3">
              <p className="font-primary text-base font-semibold text-white capitalize">
                Business Sectors
              </p>
              <Link to="/trading" className="font-primary text-sm text-white">
                Trading
              </Link>
              <Link to="/trading" className="font-primary text-sm text-white">
                Manufacturing
              </Link>
              <Link to="/trading" className="font-primary text-sm text-white">
                Banking
              </Link>
              <Link to="/trading" className="font-primary text-sm text-white">
                Construction
              </Link>
              <Link to="/trading" className="font-primary text-sm text-white">
                Energy
              </Link>
              <Link to="/trading" className="font-primary text-sm text-white">
                IT & Education
              </Link>
            </div>
            <div className="flex flex-col space-y-3">
              <p className="font-primary text-base font-semibold text-white capitalize">
                About Us
              </p>
              <Link to="/trading" className="font-primary text-sm text-white">
                Chairman Message
              </Link>
              <Link to="/trading" className="font-primary text-sm text-white">
                Mission & Vision
              </Link>
              <Link to="/trading" className="font-primary text-sm text-white">
                Management Team
              </Link>
            </div>
          </div>
          <div className="flex flex-col ml-0 mt-3 lg:ml-auto">
            <p className="hidden lg:block text-base text-center lg:text-left font-primary text-white font-semibold">
              Follow us on
            </p>
            <div className="flex space-x-5 justify-center pt-3">
              <a href="https//:facebook.com">
                <img src={facebook} alt="facebook" />
              </a>
              <a href="https//:instagram.com">
                <img src={instagram} alt="instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
