import React from "react";
import { ArrowCard } from "../../../../utilities";
import wave from "../../../../assets/images/Business/singlepage_wave.svg";
import chicagoPneumatic from "../../../../assets/images/associateCompanies/chicago.png";
import LOGO_CBL from "../../../../assets/images/associateCompanies/LOGO-CBL.png";
import goldenRolls from "../../../../assets/images/associateCompanies/golden-rolls.png";

const Associates = () => {
  return (
    <div className="-mt-80 lg:mt-0 pb-8">
      <img
        src={wave}
        alt="wave"
        className="min-w-full relative z-10"
        style={{ marginTop: "-26%" }}
      />
      <div
        className="my-24 relative z-10 space-y-24"
        style={{ marginTop: "-20%" }}
      >
        <ArrowCard
          right={false}
          title="Chicago Pneumatic"
          description="Since 1901, the Chicago Pneumatic name has represented reliability and attention to customer needs, as a global manufacturer of power tools, air compressors, generators, light towers, and hydraulic equipment for professional and industrial applications. It represents products that are engineered for high performance. In addition to pioneering design, high-impact technology and durability, Chicago Pneumatic means customer value. Our people start every single day with a passion to research, develop, manufacture and deliver new products that are meant to meet your needs not only today, but tomorrow as well. All this has come together because we have consistently practiced our brand promise — People. Passion. Performance."
          link="https://www.cp.com/fr-fr"
          img={chicagoPneumatic}
        />
        <ArrowCard
          right={true}
          title="Cheema Boilers Limited"
          description="CBL can offer comprehensive engineering, procurement, and construction (EPC) services complimenting the supply and maintenance of power generation equipment"
          link="https://www.cheemaboilers.com/"
          img={LOGO_CBL}
        />
        <ArrowCard
          right={false}
          title="GOLDEN ROLLS PVT. LTD"
          description="An ISO 9001:2015 certified organization engaged in offering a wide range of De-Husking Rubber Rolls, Polymer Elevator Bucket, Vertical Bucket Elevator, Belt Conveyor, Drag Chain Conveyor, Screw Conveyor, Pre-Cleaner, De-stoner, Precision Sizer, Truck Loader, Inkjet Printing Conveyor, Rubber Brakes (polisher), Automatic Sliding Gate, Online Magnets, Palm Husker and Accessories etc."
          link="https://www.goldenrolls.co.in/"
          img={goldenRolls}
        />
        <ArrowCard
          right={true}
          title="BEN & GAWS PVT. LTD"
          description="BEN & GAWS PVT. LTD has grown to become one of the most preferred privately-owned engineering company by manufacturing superior and innovative ‘bolted panel’ liquid storage tanks, grain storage silos & material handling equipment for different industries like grain milling, seed plants, breweries, ethanol plants, firefighting, rainwater harvesting, industrial and domestic water treatment, oil & gas, power plants, other manufacturing and processing plants etc."
          link="https://www.bengaws.com/"
          img="https://bengaws.com/img/logo-new.png"
        />
        <ArrowCard
          right={false}
          title="STERLING ABRASIVES LTD."
          description="Sterling Abrasives Limited (SAL) is an ISO 9001:2015 certified enterprise manufacturing a wide range of grinding wheels for the past five decades.We are one of the leading manufacturers of Grinding Wheels, making full range of Vitrified & Resin Bonded Grinding Wheels since last 50 years."
          link="https://sterlingabrasives.com/"
          img="http://sterlingabrasives.com/wp-content/uploads/2016/11/logo-final.png"
        />
      </div>
    </div>
  );
};

export default Associates;
