// @ts-nocheck
import { GET_VACANCIES, VACANCIES_ERROR } from "../../types";
import axios from "axios";

const apiUrl = process.env.REACT_APP_LOCAL_URI;

export const getVacancies = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiUrl}/wp/v2/vacancies`);
    dispatch({
      type: GET_VACANCIES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: VACANCIES_ERROR,
      payload: err.message,
    });
  }
};
