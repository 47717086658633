// @ts-nocheck
import {
    GET_PRODUCTS,
    GET_SINGLE_PRODUCT,
    PRODUCT_ERROR,
  } from "../../types";
  import axios from "axios";
  
  const apiUrl = process.env.REACT_APP_LOCAL_URI;
  // GET ALL PRODUCTS
  export const getAllProducts = () => async (dispatch) => {
    try {
      const {data} = await axios.get(
        `${apiUrl}/wp/v2/brand?per_page=100`
      )
      
      dispatch({
        type: GET_PRODUCTS,
        payload: data
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: PRODUCT_ERROR,
        payload: err,
      });
    }
  };
  
  // GET SINGLE PRODUCT
  export const getSingleProduct = ({id}) => async (dispatch) => {
    try {
      const {data} = await axios.get(`${apiUrl}/wp/v2/brand/${id}`);
      dispatch({
        type: GET_SINGLE_PRODUCT,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: PRODUCT_ERROR,
        payload: err,
      });
    }
  };