import React from "react";
import timeline_bg from "../../../assets/images/about/timeline_bg.png";
import { TimelineCard } from "../../../utilities";

const Timeline = () => {
  return (
    <div
      style={{ backgroundImage: `url(${timeline_bg})` }}
      className="bg-no-repeat mt-10 lg:mt-24 bg-contain"
    >
      <div className="flex flex-col justify-center items-center md:container md:mx-auto lg:mx-0 lg:px-0 xl:container xl:mx-auto">
        <p className="font-semibold text-2xl md:text-3xl text-gray-800 mb-5 font-primary">
          TIMELINE
        </p>
        <p
          className="text-center text-xl leading-7 text-gray-500 font-primary"
          style={{ width: window.innerWidth > 1024 ? "34.62rem" : "100%" }}
        >
          {" "}
          Our journey of passion, hard work and success. Read about our major
          events and success stories from different segments of time.
        </p>

        {/* ----TimeLine------ */}
        <div className="timeline mt-10 container mx-auto">
          <TimelineCard
            date="1975"
            description="Tater Group started off as a small trading office that mainly dealt Light Concrete equipment."
            dir="right"
          />
          <TimelineCard
            date="1991"
            description="Introduced construction chemicals in Nepalese market which has been a revolution since then."
            dir="left"
          />
          <TimelineCard
            date="1995"
            description="Raised to become the leading trading firm of construction equipment and construction chemicals in Nepal."
            dir="right"
          />
          <TimelineCard
            date="2005"
            description="Acquired manufacturing unit for producing Cables and PVC pipes at Satungal, Kathmandu which was named as Vishal Plastocab Ind.Pvt.Ltd. Also, we invested in SRBL. "
            dir="left"
          />
          <TimelineCard
            date="2008"
            description="Introduced new concepts including concrete pumping, transit mixing and others by piloting companies like Schwing Stetter, Chicago, Pneumatic etc. in Nepalese market."
            dir="right"
          />
          <TimelineCard
            date="2012/13"
            description="Invested in Life Insurance."
            dir="left"
          />
          <TimelineCard
            date="2013/14"
            description="Invested in Life Insurance."
            dir="right"
          />
        </div>
      </div>
    </div>
  );
};

export default Timeline;
