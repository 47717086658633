import React from "react";
import { VishalBanner, VishalAssociates } from "../../../components";
const VishalTrade = () => {
  return (
    <>
      <VishalBanner />
      <VishalAssociates />
    </>
  );
};

export default VishalTrade;
