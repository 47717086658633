import React from "react";
import { AssociateSwiper } from "../../Swiper";
const Associates = () => {
  return (
    <div className="my-14">
      <AssociateSwiper />
    </div>
  );
};

export default Associates;
