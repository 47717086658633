// @ts-nocheck
import { GET_PRODUCT_SLIDER, PRODUCT_SLIDER_ERROR } from "../../types";
import axios from "axios";

const apiUrl = process.env.REACT_APP_LOCAL_URI;

export const getProductSlider = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiUrl}/wp/v2/productslider`);
    dispatch({
      type: GET_PRODUCT_SLIDER,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: PRODUCT_SLIDER_ERROR,
      payload: err.message,
    });
  }
};
