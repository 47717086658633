import React from "react";
import mission_img from "../../../assets/images/about/mission_img.png";
import vision_img from "../../../assets/images/about/vision_img.png";

const MissionAndVision = () => {
  return (
    <div className="mt-24 mission">
      <div className="mx-auto container flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center">
          <p className="text-xl font-primary font-semibold capitalize text-red-400 pb-4">
            Tater Group's
          </p>
          <p className="text-2xl lg:text-4xl font-primary font-semibold capitalize text-black pb-4">
            MISSION, VISION & CORE VALUES
          </p>
          <div className="w-48 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
          <p className="font-primary leading-6 text-justify text-black pb-8 w-full">
            Tater Group’s distribution activity revolves around the group’s
            partnership with some of the world’s most reputed companies. Most of
            the products of these companies are distributed and supplied all
            across Nepal through a comprehensive network of distributors and
            dealers so as to reach every possible consumer segment. The Group
            also works closely with the international companies in designing
            advertisements and promotional campaign to ensure the growth of
            their brands.
          </p>
        </div>
      </div>
      <div className="block lg:grid">
        {/* --------Mission------- */}
        <div className="flex flex-col lg:grid lg:grid-cols-2 pt-5 lg:pt-24">
          <div className="hidden lg:block transform -translate-x-14 ml-0 lg:-ml-56 xl:ml-0 relative">
            <div
              className="absolute bg-gradient-to-br shadow-lg from-red-400 to-yellow-400 h-full rounded-tr-3xl rounded-br-3xl"
              style={{
                width: "35.8rem",
                height: "100%",
                transform: "rotate(9.83deg)",
                borderTopRightRadius: "6.93rem",
                borderBottomRightRadius: "6.93rem",
                zIndex: -1,
              }}
            ></div>
            <img
              src={mission_img}
              alt=""
              className="h-auto object-cover"
              style={{
                width: "36rem",
                borderTopRightRadius: "6.93rem",
                borderBottomRightRadius: "6.93rem",
              }}
            />
          </div>
          <div className="place-self-center container mx-auto mr-0 lg:mx-0 lg:mr-20 xl:mr-0 lg:px-0">
            <div>
              <p className="text-lg font-primary font-semibold capitalize text-red-400 pb-4">
                Tater Group's
              </p>
              <p className="text-2xl lg:text-3xl font-primary font-semibold capitalize text-black pb-4">
                Mission
              </p>
              <div className="w-24 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
              <p
                className="font-primary text-black text-base pb-8"
                style={{
                  width: window.innerWidth > 1024 ? "33.06rem" : "100%",
                }}
              >
                We aim to positively impact our community through successful
                business enterprise conducted with fairness and honesty. Also,
                to reach out lives and make an impact given the pursuit of
                global propagation and transformation.
              </p>
            </div>
          </div>
        </div>
        {/* ----Vision----- */}
        <div className="flex flex-col lg:hidden xl:grid xl:grid-cols-2 pt-5 xl:pt-24">
          <div className="place-self-center container mx-auto lg:mx-0">
            <div>
              <p className="text-lg font-primary font-semibold capitalize text-red-400 pb-4">
                Tater Group's
              </p>
              <p className="text-2xl lg:text-3xl font-primary font-semibold capitalize text-black pb-4">
                Vision
              </p>
              <div className="w-24 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
              <p
                className="font-primary text-black text-base pb-8"
                style={{
                  width: window.innerWidth > 1024 ? "33.06rem" : "100%",
                }}
              >
                We aim to positively impact our community through successful
                business enterprise conducted with fairness and honesty. Also,
                to reach out lives and make an impact given the pursuit of
                global propagation and transformation.
              </p>
            </div>
          </div>
          <div className="hidden lg:block relative transform xl:translate-x-14 place-self-end">
            <div
              className="absolute bg-gradient-to-br shadow-lg from-red-400 to-yellow-400 h-full rounded-tr-3xl rounded-br-3xl"
              style={{
                width: "35.8rem",
                height: "100%",
                transform: "rotate(169.93deg)",
                borderTopRightRadius: "6.93rem",
                borderBottomRightRadius: "6.93rem",
                zIndex: -1,
              }}
            ></div>
            <img
              src={vision_img}
              alt=""
              className="h-auto object-cover"
              style={{
                width: "36rem",
                borderTopLeftRadius: "6.93rem",
                borderBottomLeftRadius: "6.93rem",
              }}
            />
          </div>
        </div>
      </div>
      {/* ----------- */}
    </div>
  );
};

export default MissionAndVision;
