import React from "react";
import { ProductList, ProductBanner } from "../../components";
const Products = () => {
  return (
    <>
      <ProductBanner />
      <ProductList />
    </>
  );
};

export default Products;
