/* eslint-disable import/no-anonymous-default-export */
import { GET_SINGLE_PRODUCT, GET_PRODUCTS, PRODUCT_ERROR } from "../../types";

const initialState = {
  products: [],
  product: [],
  header: "",
  loading: true,
  error: "",
  message: "",
};
interface ProductReducerProps {
    type: string;
    payload: string;
  }

export default  (state = initialState, { type, payload }: ProductReducerProps) => {
  switch (type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload,
        loading: false,
      };
    case GET_SINGLE_PRODUCT:
      return { ...state, product: payload, loading: false };
    case PRODUCT_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
