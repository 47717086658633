// @ts-nocheck
import { connect } from "react-redux";
import { getBannerSwiper } from "../../../Redux/actions/BannerSwiper/BannerSwiperAction";
import React, { useEffect } from "react";
// Swiper thing
import { Swiper, SwiperSlide } from "swiper/react";
import { PrimaryButton } from "../../Buttons";
import circleArrow from "../../../assets/images/circle_arrow.svg";
import { Link as LinkS } from "react-scroll";
import BannerSwiperSkeleton from "./BannerSwiperSkeleton";
import "./swiper.css";
// Swiper styles
import "swiper/swiper.min.css";

const BannerSwiper = ({ getBannerSwiper, banner, loading }) => {
  useEffect(() => {
    getBannerSwiper();
  }, [getBannerSwiper]);

  return (
    <>
    {loading ? (
      <div className="w-full">
        <BannerSwiperSkeleton />
      </div>
    ) : (
      <Swiper
      className="swiper-contain h-full"
      style={
        window.innerWidth < 1024 ? { width: "100%", paddingTop: "2rem" } : {}
      }
    >
      {banner.map((item, index) => (
        <SwiperSlide
          className="swiper-slide flex flex-col"
          style={{ width: window.innerWidth === 1024 ? "90%" : "100%" }}
          key={index}
        >
          <p
            className="font-primary text-2xl lg:text-4xl font-bold text-white leading-normal"
            style={
              window.innerWidth > 1024 ? { width: "40rem" } : { width: "100%" }
            }
          >
            {item?.acf?.title}
          </p>
          <p
            className="font-primary text-sm font-normal text-white mt-5 mb-16"
            style={
              window.innerWidth > 1024 ? { width: "40rem" } : { width: "100%" }
            }
          >
            {item?.acf?.description1}
          </p>
          <PrimaryButton text="Learn More" link="/about" />
          <div className="flex items-center gap-4 mt-16">
            <LinkS to="companies" smooth={true} spy={true} offset={-80}>
              <img
                src={circleArrow}
                alt="circle arrow"
                className="transition-all ease-in-out duration-300 cursor-pointer transform hover:-translate-y-2"
              />
            </LinkS>
            <p className="text-xs text-white font-medium font-primary uppercase">
              {item?.acf?.description2}
            </p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    )}
    </>
  );
};

const mapStateToProps = (state) => ({
  banner: state.BannerSwiperList.banner,
  loading: state.BannerSwiperList.loading,
});

export default connect(mapStateToProps, { getBannerSwiper })(BannerSwiper);

