// @ts-nocheck
import React from 'react'

const BlogSkeleton = () => {
    return (
    <div className="space-x-2 my-10 mb-16 grid grid-cols-1 md:grid-cols-3 gap-3 gap-y-5 items-center" style={{marginBottom:'5rem'}}>

      <div className="border border-gray-200 shadow rounded-md  max-w-sm w-full mx-auto h-72">
        <div className=" animate-pulse bg-gray-100 h-40 w-full"/>
        <div className="animate-pulse flex flex-col space-x-4">
          <div className="flex-1 p-4 space-y-4 mt-4 py-1">
            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-full"></div> 
            </div>
          </div>
        </div>
      </div>

      <div className="border border-gray-200 shadow rounded-md  max-w-sm w-full mx-auto h-72">
        <div className=" animate-pulse bg-gray-100 h-40 w-full"/>
        <div className="animate-pulse flex flex-col space-x-4">
          <div className="flex-1 p-4 space-y-4 mt-4 py-1">
            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-full"></div> 
            </div>
          </div>
        </div>
      </div>
      
      <div className="border border-gray-200 shadow rounded-md  max-w-sm w-full mx-auto h-72">
        <div className=" animate-pulse bg-gray-100 h-40 w-full"/>
        <div className="animate-pulse flex flex-col space-x-4">
          <div className="flex-1 p-4 space-y-4 mt-4 py-1">
            <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-full"></div> 
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default BlogSkeleton
