import React from "react";
import background from "../../../assets/images/card_circle.svg";

interface iconCardProps {
  title: string;
  description: string;
  icon: string;
}
const AboutCard = ({ title, description, icon }: iconCardProps) => {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        width: window.innerWidth === 1024 ? "100%" : "21.56rem",
      }}
      className="bg-no-repeat bg-contain flex flex-col justify-evenly px-3 py-5 bg-white shadow-xl rounded-2xl transition-all ease-in-out duration-300 transform hover:-translate-y-2"
    >
      <div className="relative w-11 h-11 rounded-full shadow-lg flex items-center justify-center bg-gradient-1 to-gradient-2">
        <img src={icon} alt="" />
      </div>
      <div className="pt-5">
        <p className="font-primary text-lg font-semibold text-black px-3">
          {title}
        </p>
        <p className="font-primary pt-5 text-sm text-black px-3">
          {description}
        </p>
      </div>
    </div>
  );
};

export default AboutCard;
