// @ts-nocheck
import React from 'react'

const AssociateSwiperSkeleton = () => {
    return (
        <div className=" rounded-md  " >
        <div className="animate-pulse flex flex-col ">
          <div className=" bg-gray-100 h-32 md:h-32  lg:h-32 -mt-6 "  />
        </div>
      </div> 
    )
}

export default AssociateSwiperSkeleton
