// @ts-nocheck
import { CONTACT_ERR, SUBMIT_CONTACT } from "../../types";
import axios from "axios";

const apiUrl = process.env.REACT_APP_LOCAL_URI;

// Submit career info

export const submitContact = ({ data }) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${apiUrl}/contact-form-7/v1/contact-forms/125/feedback`,
      data
    );
    dispatch({
      type: SUBMIT_CONTACT,
      payload: response.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: CONTACT_ERR,
      payload: err,
    });
  }
};
