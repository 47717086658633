/* eslint-disable import/no-anonymous-default-export */
import { GET_TEAMS, TEAM_ERROR } from "../../types";
const initialState = {
  teams: [],
  error: "",
  loading: true,
};

interface TeamsReducerProps {
    type: string;
    payload: string;
  }

export default (state = initialState,  { type, payload }: TeamsReducerProps) => {
  switch (type) {
    case GET_TEAMS:
      return { ...state, teams: payload, loading: false };
    case TEAM_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
