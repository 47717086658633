// @ts-nocheck
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getProductSlider } from "../../../Redux";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductSwiperSkeleton from "./ProductSwiperSkeleton";

// Import swiper styles
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css";
// import from swiper core
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper/core";
// init swiper module
SwiperCore.use([Autoplay, EffectCoverflow]);

const ProductSwiper = ({ loading, getProductSlider, productSlider }) => {
  useEffect(() => {
    getProductSlider();
  }, [getProductSlider]);

  return (
    <Swiper
      slidesPerView={3}
      effect={"coverflow"}
      grabCursor={true}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
        reverseDirection: true,
      }}
      loop={true}
      spaceBetween={30}
      freeMode={true}
      className="associate-container"
      style={{ marginLeft: "unset", marginRight: "unset" }}
    >
      {loading ? (
        <div className="flex items-center justify-center">
          <ProductSwiperSkeleton />
        </div>
      ) : (
        productSlider &&
        productSlider.map((item) => (
          <SwiperSlide
            className=" -mt-0.5 flex justify-center items-center"
            key={item.id}
          >
            <img
              src={item?.acf?.product_image?.url}
              alt=""
              className="object-contain w-full"
            />
          </SwiperSlide>
        ))
      )}
    </Swiper>
  );
};

const mapStateToProps = (state) => ({
  productSlider: state.productSliderList.productSlider,
  loading: state.productSliderList.loading,
});

export default connect(mapStateToProps, { getProductSlider })(ProductSwiper);
