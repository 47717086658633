// @ts-nocheck
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllProducts } from "../../../Redux";
import { ProductCard } from "../../../utilities";
import { ProductSkeleton } from "..";

const ProductList = ({ loading, getAllProducts, products }) => {
  const [item, setItem] = useState(6);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  return (
    <>
      <div className=""></div>
      <div
        className="container   md:pt-16 md:mt-0 lg:pt-20 mb-24 md:mb-24 lg:mb-0 mx-auto flex flex-col space-y-5 relative z-0"
        // style={{ marginTop: "-33rem" }}
        style={
          window.innerWidth <= 1024
            ? { marginTop: window.innerWidth < 678 ? "-20rem" : "-18rem" }
            : {
                marginTop:
                  window.innerWidth < 1350
                    ? "-15rem"
                    : window.innerWidth < 1440
                    ? "-18rem"
                    : "-18rem",
              }
        }
      >
        <div className="w-48 md:w-80 xl:w-96 h-10 md:h-20 lg:-mt-16 xl:mt-0 mb-20 my-1 rounded bg-primary-900 shadow-xl flex items-center justify-center">
          <p className="font-primary  text-xl md:text-3xl xl:text-4xl font-medium md:font-semibold text-white uppercase">
            Our Products
          </p>
        </div>
        {loading ? (
          <ProductSkeleton />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {products.slice(0, item).map((data, index) => (
              <ProductCard
                id={data?.id}
                name={data?.acf?.brand_name}
                image={data?.acf?.brand_image?.url}
                key={index}
                link={`/products/${data.id}`}
              />
            ))}
          </div>
        )}

        <div className="place-self-center">
          {products.length > item && (
            <button
              className="font-primary place-self-center text-sm my-7 px-3 py-3 w-36 rounded-full shadow hover:shadow-xl transition-all ease-in-out duration-300 transform hover:bg-primary-800 uppercase font-semibold flex justify-center items-center self-center bg-primary-900 text-white focus:outline-none"
              onClick={(e) => {
                setItem(item + 6);
              }}
            >
              Load More
            </button>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.productList.products,
  loading: state.productList.loading,
  header: state.productList.header,
});

export default connect(mapStateToProps, { getAllProducts })(ProductList);
