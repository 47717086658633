// @ts-nocheck
import React from 'react'

const BannerSwiperSkeleton = () => {
    return (
        <div className=" rounded-md mt-10 md:mt-14 lg:mt-6 xl:mt-0 " >
        <div className="animate-pulse flex flex-col " style={{minWidth:'43vw'}}>
          <div className=" bg-primary-600 h-24 md:h-16  lg:h-32 mb-8 md:mb-6  lg:mb-12 xl:mb-8"  />
          <div className=" bg-primary-600 h-2 md:h-4  mt-1"/>
          <div className=" bg-primary-600 h-2 md:h-4  mt-1"/>
          <div className=" bg-primary-600 h-2 md:h-4  mt-1"/>
          <div className=" bg-primary-600 h-2  mt-1 md:hidden"/>
          <div className=" bg-primary-600 h-2  mt-1 md:hidden"/>
          <div className=" bg-primary-600 h-2  mt-1 md:hidden"/>
          <div className=" bg-primary-600 h-2  mt-1 md:hidden"/>

          <div className="my-14 md:my-8">
            <div className=" bg-primary-600 h-14 md:h-14 w-40 rounded-full  mt-1"/>
          </div>
          <div className=" md:my-4 mt-18 md:mt-0 ">
            <div className=" bg-primary-600 h-8 w-3/4  md:h-8 md:w-3/4 xl:mt-12 rounded-2xl  mt-8"/>
          </div>
        
        </div>
      </div> 
    )
}

export default BannerSwiperSkeleton
