import React from "react";
import { ArrowCard } from "../../../../utilities";
import wave from "../../../../assets/images/Business/wave.svg";
import vishalPlasto from "../../../../assets/images/ownCompanies/vishal_palsto.png";
import everestEquipments from "../../../../assets/images/ownCompanies/everest_eqp.png";
const Sectors = () => {
  return (
    <div
      className="-mt-80 lg:mt-0 pb-8"
      style={window.innerWidth > 1440 ? { marginTop: "4rem" } : {}}
    >
      <img
        src={wave}
        alt="wave"
        className="min-w-full relative z-10"
        style={{ marginTop: "-29%" }}
      />
      <div
        className="my-24 relative z-10 space-y-24"
        style={
          window.innerWidth > 1440
            ? { marginTop: "-28%" }
            : { marginTop: "-20%" }
        }
      >
        <ArrowCard
          right={false}
          title="Vishal Plastocab Industries Pvt Ltd."
          description="Vishal Plastocab Industries Pvt. ltd (VPIPL) is a subsidiary company of Tater Group, a conglomerate involved in Manufacturing, Trading, Banking, Hydropower and Education sectors. VPIPL, with its dual units in Thankot, Kathmandu, has strived to appreciable heights in short span maintaining great quality Standards ever since. It has modernized its plant with experienced manpower, quality raw materials and sound testing facilities. It also has attained various Nepal Standard (NS) marks for its products and is an ISO 9001:2008 accredited company."
          link="https://www.vishalplastocab.com/"
          img={vishalPlasto}
        />
        <ArrowCard
          title="Everest Equipments Pvt Ltd."
          right={true}
          description="Everest Equipments Private Limited is a leading construction equipment manufacturing company. We at EVEREST believe in developing high quality products, maintaining trust and providing total customer satisfaction."
          link="https://www.everestconstructionequipment.com/"
          img={everestEquipments}
        />
      </div>
    </div>
  );
};

export default Sectors;
