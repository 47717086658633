// @ts-nocheck
import React from 'react'

const ProductSwiperSkeleton = () => {
    return (
      <>
        <div className="border border-primary-800 shadow-3xl mb-2  max-w-sm w-10/12 mx-auto h-16 md:h-44 lg:h-52">
            <div className=" animate-pulse bg-primary-800 h-16 md:h-44 lg:h-52 w-full"/>
        </div>

        <div className="border border-primary-800 shadow-3xl mb-2 max-w-sm w-full mx-auto h-20 md:h-48 lg:h-60">
            <div className=" animate-pulse bg-primary-800 h-20 md:h-48 lg:h-60 w-full"/>
        </div>

        <div className="border border-primary-800 shadow-3xl mb-2 max-w-sm w-full mx-auto h-16 md:h-44 lg:h-52">
            <div className=" animate-pulse bg-primary-800 h-16 md:h-48 lg:h-44 w-full"/>
        </div>
      </>
    )
}

export default ProductSwiperSkeleton
