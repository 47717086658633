import React from "react";
import { BankingBanner, BankingSectors } from "../../../components";

const Banking = () => {
  return (
    <>
      <BankingBanner />
      <BankingSectors />
    </>
  );
};

export default Banking;
