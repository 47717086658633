import React from "react";
import {
  HomePageBanner,
  Achievements,
  Companies,
  ValuableQuotes,
  Associates,
  Testimonials,
} from "../../components";

const Home = () => {
  return (
    <>
      <HomePageBanner />
      <Companies />
      <Achievements />
      <ValuableQuotes />
      <Associates />
      <Testimonials />
    </>
  );
};

export default Home;
