// @ts-nocheck
import React from "react";
import brTater from "../../assets/images/person/BR-tater.png";

const ChairmanMessage = () => {
  return (
    <div className=" mx-auto bg-white rounded-xl shadow-md overflow-hidden mt-2 mb-20">
      <div className="md:flex">
        <div className="md:flex-shrink-0">
          <img
            className="h-48 w-full object-contain md:h-full md:w-48"
            src={brTater}
            alt=""
          />
        </div>
        <div className="p-8">
          <div className="font-primary text-sm text-gray-500">Chairman</div>
          <p className="font-primary text-base font-semibold text-gray-800 capitalize">
            Mr. Bachhraj Tater
          </p>
          <p className="mt-2 text-gray-500">
            Tater Group manufactures and trades in the construction, hydro power
            and agricultural industries, serving industry sectors effectively
            through a group of highly specialized subsidiary companies. Our
            journey in the last 45+ years has been an amazing experience in
            terms of growth and development and it is an absolute pleasure to
            present my perspective as the chairman. Although the experiences we
            endured remains to be the chapters of our glorious history, it
            motivates and influences our efforts today.
            <br />
            At Tater Group, in accordance with the values that we remain loyal
            to, we strive to enrich lives by delivering products of highest
            quality standards. We formulate business policies that optimize the
            living standards of our people through the services that can be
            trusted upon.
            <br />
            It is always amazing to witness the resulsYears on, it is with pride
            that we celebrate the success of esteemed projects like Arun Valley
            and Rhidi hydro power including the customers’ trust we have earned.
            The continuous growth we manifest is as a result of hard work and
            persistence put by all our functioning units. I would like to use my
            privilege to express my gratitude to our esteemed partners,
            customers, vendors and all the functioning units who have been our
            source of workforce. You all play a significant role in upbringing
            the company’s recognition and I am optimistic about our future
            endeavors and your contribution in it.
            <br />
            <i>Change is inevitable, but growth is what we work for.</i>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChairmanMessage;
