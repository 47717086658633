import React from "react";
import { TechnologyBanner, TechnologySectors } from "../../../components";
const Technology = () => {
  return (
    <>
      <TechnologyBanner />
      <TechnologySectors />
    </>
  );
};

export default Technology;
