// @ts-nocheck
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllBlogs } from "../../../Redux/actions/Blogs/BlogAction";
import blogVector from "../../../assets/images/Blog/blogVector.svg";
import { BlogCard } from "../../../utilities";
import { BlogSkeleton } from "..";

const Blogs = ({ loading, getAllBlogs, blogs }) => {
  const [item, setItem] = useState(5);

  useEffect(() => {
    getAllBlogs();
  }, [getAllBlogs]);

  const first = blogs?.slice(1, 5);
  const second = blogs?.slice(5, item);
  const style = () => {
    const width = window.innerWidth;
    if (width === 1024) {
      return {
        marginTop: "-28.5%",
      };
    } else if (window.innerWidth === 768) {
      return {
        marginTop: "-31.5%",
      };
    } else if (window.innerWidth < 768) {
      return {
        marginTop: "-55.5%",
      };
    } else if (window.innerWidth > 1024) {
      return {
        marginTop: "-23.5%",
      };
    }
  };
  return (
    <>
      <img
        src={blogVector}
        alt="wave"
        className="min-w-full relative"
        style={style()}
      />
      <div
        className="my-20 relative z-10 space-y-8 container mx-auto"
        style={{ marginTop: "-25%" }}
      >
        {loading ? (
          <BlogSkeleton />
        ) : (
          <>
            <div className="grid lg:grid-cols-2 gap-4 pt-7 lg:pt-16">
              <div>
                <BlogCard
                  title={blogs[0]?.acf?.blog_title}
                  description={blogs[0]?.acf?.blog_description.slice(0, 100)}
                  date={blogs[0]?.modified}
                  id={blogs[0]?.id}
                  image={blogs[0]?.acf?.featured_image?.url}
                  small={false}
                  company={blogs[0]?.acf?.company_name}
                />
              </div>
              <div className="block md:grid md:grid-cols-2 md:grid-rows-2 gap-4">
                {first.map((data) => (
                  <BlogCard
                    small
                    title={data?.acf?.blog_title}
                    id={data?.id}
                    description={data?.acf?.blog_description?.slice(0, 100)}
                    date={data?.modified}
                    image={data?.acf?.featured_image?.url}
                    company={data?.acf?.company_name}
                  />
                ))}
              </div>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 my-10">
              {item > 6 && (
                <>
                  {second.map((data) => (
                    <BlogCard
                      small
                      title={data?.acf?.blog_title}
                      id={data?.id}
                      description={data?.acf?.blog_description.slice(0, 100)}
                      date={data?.modified}
                      image={data?.acf?.featured_image?.url}
                      company={data?.acf?.company_name}
                    />
                  ))}
                </>
              )}
            </div>
            <div className=" flex justify-center items-center">
              {blogs.length > item && (
                <button
                  className="font-primary place-self-center text-sm px-3 py-3 w-36 rounded-full shadow hover:shadow-xl transition-all ease-in-out duration-300 transform hover:bg-primary-800 uppercase font-semibold flex justify-center items-center self-center bg-primary-900 text-white focus:outline-none"
                  onClick={(e) => {
                    setItem(item + 6);
                  }}
                >
                  Load More
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  blogs: state.blogList.blogs,
  loading: state.blogList.loading,
  header: state.blogList.header,
});

export default connect(mapStateToProps, { getAllBlogs })(Blogs);
