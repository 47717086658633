import React from "react";
import { NavLink } from "react-router-dom";
import AccordionNav from "./AccordionNav";
import { useLocation } from "react-router-dom";

interface IProps {
  isOpen: boolean;
  toggle: any;
}

const Sidebar = ({ isOpen, toggle }: IProps) => {
  const { pathname } = useLocation();
  // Business list
  const lists = [
    {
      id: 1,
      url: "/trading",
      title: "Trading",
    },
    {
      id: 2,
      url: "/manufacturing",
      title: "Manufacturing",
    },
    {
      id: 3,
      url: "/banking",
      title: "Banking",
    },
    {
      id: 4,
      url: "/construction",
      title: "Construction",
    },
    {
      id: 5,
      url: "/energy",
      title: "Energy",
    },
    {
      id: 6,
      url: "/it_edu",
      title: "It & Education",
    },
  ];
  return (
    <div
      className="h-screen w-screen z-50 transform bg-gradient-to-tl from-yellow-200 to-primary-900 flex flex-col transition-all ease-in-out duration-700"
      style={{
        opacity: isOpen ? "140%" : "0",
        position: "fixed",
        top: isOpen ? "0" : "-140%",
      }}
    >
      <i
        className="fas fa-times text-3xl absolute right-0 py-4 px-8 text-gray-800"
        onClick={toggle}
      ></i>
      <ul className="flex flex-col items-center p-7 px-9 font-primary uppercase text-sm font-semibold text-gray-600 pt-20">
        <li className="hover:text-gray-400 transition-all ease-in-out duration-150  pb-14 text-3xl">
          <NavLink
            to="/"
            exact
            className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
            activeClassName="text-primary-900"
            onClick={toggle}
          >
            Home
          </NavLink>
        </li>
        <li className="hover:text-gray-400 transition-all ease-in-out duration-150 pb-14 text-3xl">
          <NavLink
            to="/about"
            className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
            activeClassName="text-primary-900"
            onClick={toggle}
          >
            About
          </NavLink>
        </li>
        <li className="hover:text-gray-400 transition-all ease-in-out duration-150 pb-14 text-3xl">
          <AccordionNav title="Business Sector" lists={lists} toggle={toggle} />
        </li>
        <li className="hover:text-gray-400 transition-all ease-in-out duration-150 pb-14 text-3xl">
          <NavLink
            to="/products"
            className={`font-primary text-sm font-semibold uppercase ${
              pathname.includes("blogs") ? "text-primary-900" : "text-gray-500"
            } transition-all ease-in-out duration-300 hover:text-primary-900`}
            activeClassName="text-primary-900"
            onClick={toggle}
          >
            Products
          </NavLink>
        </li>
        <li className="hover:text-gray-400 transition-all ease-in-out duration-150 pb-14 text-3xl">
          <NavLink
            to="/blog_media"
            className={`font-primary text-sm font-semibold uppercase ${
              pathname.includes("blogs") ? "text-primary-900" : "text-gray-500"
            } transition-all ease-in-out duration-300 hover:text-primary-900`}
            activeClassName="text-primary-900"
            onClick={toggle}
          >
            Blog/News
          </NavLink>
        </li>
        <li className="hover:text-gray-400 transition-all ease-in-out duration-150 pb-14 text-3xl">
          <NavLink
            to="/career"
            className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
            activeClassName="text-primary-900"
            onClick={toggle}
          >
            Career
          </NavLink>
        </li>
        <li className="hover:text-gray-400 transition-all ease-in-out duration-150 pb-14 text-3xl">
          <NavLink
            to="/contact"
            className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
            activeClassName="text-primary-900"
            onClick={toggle}
          >
            Contact us
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
