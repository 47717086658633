/* eslint-disable import/no-anonymous-default-export */
import { GET_VACANCIES, VACANCIES_ERROR } from "../../types";
const initialState = {
loading: true,
  vacancies: [],
  error: "",
};

interface VacanciesReducerProps {
    type: string;
    payload: string;
  }

export default (state = initialState,  { type, payload }: VacanciesReducerProps) => {
  switch (type) {
    case GET_VACANCIES:
      return { ...state, vacancies: payload, loading: false };
    case VACANCIES_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

