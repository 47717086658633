import { combineReducers } from "redux";
import BlogReducer from "./Blogs";
import TeamsReducer from './Teams'
import VacanciesReducer from "./Vacancies";
import AssociateReducer from "./Associate";
import BannerSwiperReducer from "./BannerSwiper/BannerSwiperReducer";
import ProductsReducer from "./Products/ProductsReducer";
import ProductSliderReducer from "./ProductSlider/ProductSliderReducer";
import PopupAlertReducer from "./PopupAlert/PopupAlertReducer";
export default combineReducers({
  blogList: BlogReducer,
  TeamsList:TeamsReducer,
  VacanciesList: VacanciesReducer,
  AssociateList: AssociateReducer,
  BannerSwiperList: BannerSwiperReducer,
  productList: ProductsReducer,
  productSliderList: ProductSliderReducer,
  alertList: PopupAlertReducer
});


