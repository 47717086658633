import React from "react";
interface InvertButtonProps {
  text: string;
}
const InvertButton = ({ text }: InvertButtonProps) => {
  return (
    <button
      className={`py-5 px-3 w-40 bg-gray-50 text-sm font-semibold uppercase rounded-full text-black font-primary transition-all ease-in-out duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1 focus:ring-4 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 hover:bg-gray-200`}
    >
      {text}
    </button>
  );
};

export default InvertButton;
