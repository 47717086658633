// @ts-nocheck
import { GET_TEAMS, TEAM_ERROR } from "../../types";
import axios from "axios";

const apiUrl = process.env.REACT_APP_LOCAL_URI;

export const getTeams = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiUrl}/wp/v2/members`);
    dispatch({
      type: GET_TEAMS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: TEAM_ERROR,
      payload: err.message,
    });
  }
};
