/* eslint-disable import/no-anonymous-default-export */
import { GET_BANNER_SWIPER, BANNER_SWIPER_ERROR } from "../../types";
const initialState = {
loading: true,
  banner: [],
  error: "",
};

interface BannerReducerProps {
    type: string;
    payload: string;
  }

export default (state = initialState,  { type, payload }: BannerReducerProps) => {
  switch (type) {
    case GET_BANNER_SWIPER:
      return { ...state, banner: payload, loading: false };
    case BANNER_SWIPER_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

