import React from "react";
import { ConstructionBanner, ConstructionSectors } from "../../../components";
const Construction = () => {
  return (
    <>
      <ConstructionBanner />
      <ConstructionSectors />
    </>
  );
};

export default Construction;
