import React from "react";

const TopBar = () => {
  return (
    <div className="bg-transparent py-3 relative z-20">
      <div className="container mx-auto flex">
        <div className="ml-auto flex space-x-4">
          <div className="flex space-x-3 items-center">
            <i className="fas fa-phone text-xl text-white"></i>
            <div>
              <p className="font-primary text-xs font-semibold uppercase text-white">
                Help-line
              </p>
              <a
                href="tel:+977 01415632"
                className="font-primary text-xs text-white hover:underline"
              >
                9801000095
              </a>
            </div>
          </div>
          <div className="md:flex space-x-3 items-center hidden">
            <i className="fas fa-envelope text-xl text-white"></i>
            <div>
              <p className="font-primary text-xs font-semibold uppercase text-white">
                Email
              </p>
              <a
                href="info@tater-group.com"
                className="font-primary text-xs text-white  hover:underline"
              >
                info@tater-group.com
              </a>
            </div>
          </div>
          <div className="flex space-x-3 items-center">
            <i className="fas fa-map-marker-alt text-xl text-white"></i>
            <div>
              <p className="font-primary text-xs font-semibold uppercase text-white">
                Location
              </p>
              <p className="font-primary text-xs text-white py-1">
                Balkumari, Kathmandu, Nepal
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
