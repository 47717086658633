import React from "react";
import { ArrowCard } from "../../../../utilities";
import wave from "../../../../assets/images/Business/wave.svg";
import dakshyaNepal from "../../../../assets/images/ownCompanies/dakshyaNepal.png";

const Sectors = () => {
  return (
    <div
      className="-mt-80 lg:mt-0 pb-8"
      style={window.innerWidth > 1440 ? { marginTop: "4rem" } : {}}
    >
      <img
        src={wave}
        alt="wave"
        className="min-w-full relative z-10"
        style={{ marginTop: "-29%" }}
      />
      <div
        className="my-24 relative z-10 space-y-24"
        style={
          window.innerWidth > 1440
            ? { marginTop: "-28%" }
            : { marginTop: "-20%" }
        }
      >
        <ArrowCard
          right={false}
          title="Dakshya Nepal"
          description="Dakshya Nepal is a company fundamentally focused on digitizing the education system of the country. We deliver the proper mechanisms to revolutionize the traditional way of learning to make it more productive and effective."
          link="https://dakshyanepal.com/"
          img={dakshyaNepal}
        />
      </div>
    </div>
  );
};

export default Sectors;
