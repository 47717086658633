// @ts-nocheck
import React from 'react'

const BlogSingleSkeleton = () => {
    return (
        <div class=" rounded-md p-4 my-18 min-w-full mt-16 w-full mx-auto">
        <div class="animate-pulse flex flex-col">
          <div class=" bg-gray-200 h-5 w-28 mb-12"/>
          <div class=" bg-gray-200 h-8 w-28 mt-4"/>
          <div class=" bg-gray-200 h-6 w-64 mt-6"/>
          <div class=" bg-gray-200 h-72 lg:h-screen w-full mt-10"/>
          <div class=" bg-gray-200 h-6 w-full mt-12"/>
          <div class=" bg-gray-200 h-6 w-full mt-2"/>
          <div class=" bg-gray-200 h-6 w-full mt-2"/>
          <div class=" bg-gray-200 h-6 w-full mt-2"/> 
        </div>
      </div> 
    )
}

export default BlogSingleSkeleton
