// @ts-nocheck
import React from "react";
import aboutBg from "../../../assets/images/about/about_banner.png";
import historyIcon from "../../../assets/images/about/historyIcon.png";
import historyImage from "../../../assets/images/about/historyImage.png";
import teamIcon from "../../../assets/images/about/teamIcon.png";
import networkIcon from "../../../assets/images/about/networkIcon.png";
import missionIcon from "../../../assets/images/about/missionIcon.png";
import bannerWave from "../../../assets/images/about/banner_wave.svg";
import { NavigationCard } from "../../../utilities";
// links
import { Link as LinkS } from "react-scroll";

const bannerText = () => {
  const innerWidth = window.innerWidth;
  if (innerWidth === 1366) {
    return {
      transform: "translateY(0rem)",
    };
  } else if (innerWidth >= 1440) {
    return {
      transform: "translateY(-5rem)",
    };
  } else if (innerWidth <= 768) {
    return {
      trnasform: "translateY(0rem)",
    };
  }
};

const AboutBanner = () => {
  return (
    <div>
      <div
        className="  flex items-center justify-center bg-no-repeat bg-fixed lg:bg-cover"
        style={{
          backgroundImage: `url(${aboutBg})`,
          transform: `translateY(-9.1rem)`,
          marginBottom: "-9.1rem",
          height: window.innerWidth > 768 ? "80vh" : "50vh",
        }}
      >
        <div className="mx-auto container">
          <p
            className="text-white font-bold text-center text-4xl mt-10 md:mt-0 lg:text-6xl"
            style={bannerText()}
          >
            WHO ARE WE
          </p>
        </div>
      </div>
      <img
        className="w-full relative hidden md:block"
        src={bannerWave}
        alt="Banner wave"
        style={
          window.innerWidth < 1024
            ? { marginTop: window.innerWidth > 768 ? "-34%" : "-45%" }
            : { marginTop: window.innerWidth <= 1024 ? "-35rem" : "-34%" }
        }
      />

      {/* -----Category navigation------------ */}

      <div
        className="hidden xl:block"
        style={
          window.innerWidth < 1440
            ? { marginTop: "-40%" }
            : { marginTop: "-35%" }
        }
      >
        <div className="flex flex-col space-y-3 fixed right-0 top-40 z-20">
          <LinkS to="history" spy={true} smooth={true}>
            <NavigationCard icon={historyIcon} title="History" />
          </LinkS>
          <LinkS to="mission" spy={true} smooth={true}>
            <NavigationCard icon={missionIcon} title="Mission" />
          </LinkS>
          <LinkS to="team" spy={true} smooth={true}>
            <NavigationCard icon={teamIcon} title="Teams" />
          </LinkS>
          <LinkS to="network" spy={true} smooth={true}>
            <NavigationCard icon={networkIcon} title="Network" />
          </LinkS>
        </div>
      </div>

      {/* History Section */}
      <div
        className="mx-auto container relative mt-5 md:-mt-32 lg:-mt-40 xl:mt-24 history"
        id="history"
      >
        <div className="flex flex-col lg:grid lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <p className="text-lg font-primary font-semibold capitalize text-red-400 pb-4">
              Tater Group's
            </p>
            <p className="text-2xl lg:text-4xl font-primary font-semibold capitalize text-black pb-4">
              History
            </p>
            <div className="w-24 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
            <p
              className="font-primary text-justify text-black text-base pb-8"
              style={{ width: window.innerWidth > 1024 ? "41.31rem" : "100%" }}
            >
              The Tater Group introduced construction equipment and chemicals in
              Nepal at such time when traditional methods of construction were
              prevalent. It was a struggle to intervene the existing
              conventional style. However, Mr. Bachhraj Tater (the founder of
              Tater Group) landed his new ideas with an unwavering dedication
              which eventually became successful. His success brought a gradual
              transformation in the construction module of Nepal which now
              applies to almost every kind of construction project. It gained
              momentum with time and created a pathway for other inception plans
              and success stories that followed.
            </p>
          </div>
          <img
            src={historyImage}
            alt=""
            className="ml-5 xl:ml-36 hidden lg:block"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
