import React from "react";
interface IProps {
  Mimage: string;
  name: string;
  position: string;
  facebook: string;
  twitter: string;
  linkedin: string;
}

const MemberCard = ({
  Mimage,
  name,
  position,
  facebook,
  twitter,
  linkedin,
}: IProps) => {
  return (
    <div className="shadow-md mb-20 rounded-md flex flex-col items-center w-64 h-44 md:w-52 md:h-32 lg:w-64 lg:h-44 bg-white transform transition-all ease-in-out duration-300 hover:shadow-half hover:-translate-y-2">
      <img
        src={Mimage}
        alt={`${name}`}
        className="w-20 h-20 object-cover rounded-full transform -translate-y-10 -mb-5"
      />
      <p className="font-primary text-base font-semibold text-gray-800 capitalize">
        {name}
      </p>
      <p className="font-primary text-sm text-gray-500">{position}</p>
      <div className="flex place-self-center space-x-4 mt-auto mb-5">
        {facebook && (
          <a href={facebook}>
            <i className="fab fa-facebook text-xl transition-all ease-in-out duration-300 hover:text-blue-500"></i>
          </a>
        )}
        {twitter && (
          <a href={twitter}>
            <i className="fab fa-twitter text-xl transition-all ease-in-out duration-300 hover:text-blue-300"></i>
          </a>
        )}
        {linkedin && (
          <a href={linkedin}>
            <i className="fab fa-linkedin-in text-xl transition-all ease-in-out duration-300 hover:text-blue-500"></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default MemberCard;
