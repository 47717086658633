import React from "react";
import circle from "../../../assets/images/about/about_background_circles.png";
interface navigationProps {
  icon: string;
  title: string;
}

const NavigationCard = ({ icon, title }: navigationProps) => {
  return (
    <div>
      <div
        className={`h-28 w-28 bg-white bg-no-repeat rounded-tl-xl rounded-bl-xl shadow-2xl py-3 px-4 flex flex-col justify-evenly items-center transition-all ease-in-out duration-300 transform hover:-translate-y-2 hover:shadow-2xl cursor-pointer relative`}
      >
        <div
          className={`flex flex-col justify-center items-center h-14 w-14 bg-gradient-to-br from-red-400 to-yellow-400 bg-no-repeat rounded-full`}
        >
          <img src={icon} alt="Icon" />
        </div>

        <p className={"font-primary text font-bold text-gray-800"}>{title}</p>
        <img
          src={circle}
          alt="circle"
          className="absolute left-0 pl-2 bottom-0 pb-2"
        />
      </div>
    </div>
  );
};

export default NavigationCard;
