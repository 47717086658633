import React from "react";
import card_bg from "../../../assets/images/Business/arrow_rectangle.png";
import geometry from "../../../assets/images/Business/geometry_shapes.png";
import DescriptiveCard from "../DescriptiveCard";
import { SecondaryButton } from "../../../components";

interface arrowProps {
  right: boolean;
  img: string;
  description: string;
  link: string;
  title: string;
}

const ArrowCard = ({ right, img, description, link, title }: arrowProps) => {
  if (!right) {
    return (
      <div className="grid relative lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2 mx-auto container">
        <div className="filter lg:block hidden drop-shadow-lg">
          <div
            className="flex justify-center items-center rounded-lg relative"
            style={{
              clipPath:
                "polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)",
              height: "20rem",
              width: "25.68rem",
              backgroundColor: "#FFF9F9",
            }}
          >
            <img src={card_bg} alt="" className="absolute -top-4 left-10" />
            <img src={img} alt="" className="w-auto max-h-36" />
          </div>
        </div>
        <div className="container mx-auto place-self-center">
          <DescriptiveCard
            width={43.5}
            title={title}
            description={description}
            img={img}
          />
          <SecondaryButton text="Learn More" link={link} />
        </div>
        <img
          src={geometry}
          alt=""
          className="absolute right-0 bottom-0 w-48 h-40"
          style={{ zIndex: -1 }}
        />
      </div>
    );
  } else {
    return (
      <div className="grid relative lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2 mx-auto container">
        <div className="mx-auto container place-self-center">
          <DescriptiveCard
            width={43.5}
            title={title}
            description={description}
            img={img}
          />
          <SecondaryButton text="Learn More" link={link} />
        </div>
        <div className="filter lg:block hidden drop-shadow-lg place-self-end">
          <div
            className="flex justify-center items-center rounded-lg relative"
            style={{
              clipPath:
                "polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)",
              height: "20rem",
              width: "25.68rem",
              backgroundColor: "#FFF9F9",
            }}
          >
            <img
              src={card_bg}
              alt=""
              className="absolute top-0 right-10 transform rotate-180"
              style={{ zIndex: -1 }}
            />
            <img src={img} alt="" className="w-auto max-h-36" />
          </div>
        </div>
      </div>
    );
  }
};

export default ArrowCard;
