/* eslint-disable import/no-anonymous-default-export */
import { GET_PRODUCT_SLIDER, PRODUCT_SLIDER_ERROR } from "../../types";
const initialState = {
  productSlider: [],
  loading: true,
  error: "",
};

interface ProductSliderReducerProps {
    type: string;
    payload: string;
  }

export default (state = initialState,  { type, payload }: ProductSliderReducerProps) => {
  switch (type) {
    case GET_PRODUCT_SLIDER:
      return { ...state, productSlider: payload, loading: false };
    case PRODUCT_SLIDER_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
