// @ts-nocheck
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getTeams } from "../../Redux/actions/Teams/TeamAction";
import { MemberCard } from "../../utilities";
import Spinner from "../../assets/Spinner.svg";
import { QuoteSwiper } from "..";
// import ChairmanMessage from "./ChairmanMessage";

const Teams = ({ getTeams, teams, loading }) => {
  useEffect(() => {
    getTeams();
  }, [getTeams]);

  return (
    <div>
      {/* <ChairmanMessage /> */}
      <div
          className=" mb-24 mt-0 sm:mt-14 lg:mt-0"
          style={
            window.innerWidth < 768 ? { transform: "scale3d(.8, .8, 1.5)" } : {}
          }
        >
          <QuoteSwiper />
        </div>
      <div className="md:grid flex flex-col  items-center lg:justify-self-auto">
        <div className="md:grid md:grid-cols-3 lg:grid-cols-4">
          {loading ? (
            <img src={Spinner} alt="" />
          ) : (
            teams &&
            teams.map((team, index) => (
              <MemberCard
                key={index}
                Mimage={team?.acf?.member_image?.url}
                name={team?.acf?.member_name}
                position={team?.acf?.member_position}
                facebook={team?.acf?.facebook_link}
                twitter={team?.acf?.twitter_link}
                linkedin={team?.acf?.linkedin_link}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  teams: state.TeamsList.teams,
  loading: state.TeamsList.loading,
});

export default connect(mapStateToProps, { getTeams })(Teams);
