import React from "react";
import briefcase from "../../../../assets/images/Business/briefcase.svg";
import banner from "../../../../assets/images/Business/banner_background.png";
import trading_png from "../../../../assets/images/Business/trading.png";

const Banner = () => {
  return (
    <div
      className="bg-no-repeat bg-cover w-full h-full relative"
      style={
        window.innerWidth < 1440
          ? {
              backgroundImage: `url(${banner})`,
              transform: "translateY(-9.1rem)",
            }
          : {
              backgroundImage: `url(${banner})`,
              transform: "translateY(-9.1rem)",
              paddingBottom: "4rem",
            }
      }
    >
      <div
        className="container mx-auto grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2"
        style={{
          paddingTop: "11.8rem",
          paddingBottom: "13.8rem",
        }}
      >
        <div className="pt-10">
          <div className="flex lg:space-x-10 space-x-2 items-center">
            <img src={briefcase} alt="" className="h-28 w-28" />
            <div className="flex flex-col justify-center space-y-3">
              <p className="font-primary lg:text-2xl text-xl font-bold uppercase text-white">
                tater's group
              </p>
              <p className="font-primary lg:text-4xl text-3xl font-bold uppercase text-white">
                trading sector
              </p>
            </div>
          </div>
          <p className="font-primary text-lg font-normal pt-2 text-white">
            Tater Group has been representing various world renowned companies
            in Nepal since the last five decades. The major service is trading
            of construction materials and power equipments within and across the
            borders. Here are some companies that is involved in trading of
            construction, tunneling, industrial and power equipment.
          </p>
        </div>
        <div className="place-self-center">
          <img
            src={trading_png}
            alt=""
            className="absolute top-14 right-0 h-80 mt-24 hidden lg:block"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
