// @ts-nocheck
import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";

const Messenger = () => {
  return (
    <div>
      <MessengerCustomerChat
        pageId="355113531486656"
        appId="515755112877475"
        themeColor="#F7931C"
      />
    </div>
  );
};

export default Messenger;
