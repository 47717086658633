// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  id: string;
  image: string;
  name: string;
}

const ProductCard = ({ image, name, id, link }: IProps) => {
  return (
    <>
      <Link to={link}>
        <div
          className="flex flex-col items-center rounded cursor-pointer shadow-half transform transition-all ease-in-out duration-300 hover:-translate-y-2 hover:shadow-lg"
          style={{
            backgroundImage:
              "linear-gradient(180deg, #F7941E 0%, #FFB364 100%)",
          }}
        >
          <img src={image} alt="" className="w-full h-48 object-cover" />
          <div className="bg-skin-400 text-center py-3 px-5 shadow-sm w-full">
            <p className="font-primary font-semibold text-sm text-white uppercase">
              {name}
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProductCard;
