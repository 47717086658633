import React from "react";
import "./timeline.css";
interface timeLineProps {
  date: string;
  description: string;
  dir: string;
}

const TimelineCard = ({ date, description, dir }: timeLineProps) => {
  return (
    <div className={`contain ${dir}`} data-aos={`fade-${dir}`}>
      <div className="content shadow-xl bg-gray-100 transition-all ease-in-out duration-300 hover:shadow-2xl transform hover:-translate-y-2">
        <div className="flex">
          <p className="font-primary text-primary text-xl font-semibold pr-4 text-red-400 tracking-wider">
            {date}
          </p>
        </div>
        <p className="pt-3 text-black font-primary tracking-wide text-sm">
          {description}
        </p>
      </div>
    </div>
  );
};

export default TimelineCard;
