import React from "react";
import { Link } from "react-router-dom";

interface Button {
  text: string;
  link: string;
}
const PrimaryButton = ({ text, link }: Button) => {
  return (
    <Link
      to={link}
      className={`py-5 px-3 w-40 flex justify-center bg-primary-600 text-sm font-semibold uppercase rounded-full text-white font-primary transition-all ease-in-out duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1 focus:ring-4 focus:ring-primary-900 focus:outline-none focus:ring-opacity-50`}
    >
      {text}
    </Link>
  );
};

export default PrimaryButton;
