import {
    GET_BLOGS,
    GET_SINGLE_BLOG,
    BLOG_ERROR,
  } from "../../types";
  import axios from "axios";
  
  const apiUrl = process.env.REACT_APP_LOCAL_URI;
  // GET ALL BLOGS
  export const getAllBlogs = () => async (dispatch) => {
    try {
      const {data} = await axios.get(
        `${apiUrl}/wp/v2/blogs?per_page=100`
      );
      dispatch({
        type: GET_BLOGS,
        payload: data
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: BLOG_ERROR,
        payload: err,
      });
    }
  };
  
  // GET SINGLE BLOG
  export const getSingleBlog = ({id}) => async (dispatch) => {
    try {
      const {data} = await axios.get(`${apiUrl}/wp/v2/blogs/${id}`);
      dispatch({
        type: GET_SINGLE_BLOG,
        payload: data,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: BLOG_ERROR,
        payload: err,
      });
    }
  };