// @ts-nocheck
import { GET_BANNER_SWIPER, BANNER_SWIPER_ERROR } from "../../types";
import axios from "axios";

const apiUrl = process.env.REACT_APP_LOCAL_URI;

export const getBannerSwiper = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiUrl}/wp/v2/homeswiper`);
    dispatch({
      type: GET_BANNER_SWIPER,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: BANNER_SWIPER_ERROR,
      payload: err.message,
    });
  }
};
