import React from "react";
import testimonialBg from "../../../assets/images/testimonial-bg.svg";
import { TestimonialSwiper } from "../../Swiper";

const Testimonials = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${testimonialBg})`,
        backgroundPositionY: "-20rem",
      }}
      className="bg-no-repeat bg-cover pt-20 sm:pt-36 pb-32 lg:pb-96 xl:pb-36 relative"
    >
      <div
        className="flex flex-col lg:grid lg:items-center"
        style={{ gridTemplateColumns: ".6fr 1fr" }}
      >
        <p
          className="container font-primary text-2xl text-center lg:text-left sm:text-3xl font-semibold"
          style={{ width: "100%" }}
        >
          Testimonial's {window.innerWidth === 768 && <br />}
          From Valuable Clients
        </p>
        <div className="mt-10 lg:mt-0">
          <TestimonialSwiper />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
