// @ts-nocheck
import { GET_ASSOCIATE, ASSOCIATE_ERROR } from "../../types";
import axios from "axios";

const apiUrl = process.env.REACT_APP_LOCAL_URI;

export const getAssociate = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiUrl}/wp/v2/associates`);
    dispatch({
      type: GET_ASSOCIATE,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ASSOCIATE_ERROR,
      payload: err.message,
    });
  }
};
