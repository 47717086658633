import React from "react";

interface DescriptiveCardProps {
  title: string;
  description: string;
  width: number;
  img: string;
}
const DescriptiveCard = ({
  title,
  description,
  width,
  img,
}: DescriptiveCardProps) => {
  return (
    <div>
      <p className="text-lg font-primary font-semibold capitalize text-red-400 pb-4">
        Tater Group's
      </p>
      <img src={img} alt="" className="py-8 lg:hidden" />
      <p className="text-2xl font-primary font-semibold capitalize text-black pb-4">
        {title}
      </p>
      <div className="w-24 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
      <p
        className="font-primary text-black text-sm pb-8"
        style={{ width: `${width} rem` }}
      >
        {description}
      </p>
    </div>
  );
};

export default DescriptiveCard;
