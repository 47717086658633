/* eslint-disable import/no-anonymous-default-export */
import { GET_SINGLE_BLOG, GET_BLOGS, BLOG_ERROR } from "../../types";

const initialState = {
  blogs: [],
  blog: [],
  header: "",
  loading: true,
  error: "",
  message: "",
};
interface BlogReducerProps {
    type: string;
    payload: string;
  }

export default  (state = initialState, { type, payload }: BlogReducerProps) => {
  switch (type) {
    case GET_BLOGS:
      return {
        ...state,
        blogs: payload,
        loading: false,
      };
    case GET_SINGLE_BLOG:
      return {...state, blog: payload, loading: false };
    case BLOG_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
