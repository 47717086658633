import React from "react";
import { ArrowCard } from "../../../../utilities";
import wave from "../../../../assets/images/Business/singlepage_wave.svg";

// Companies
// import maruti from "../../../../assets/images/associateCompanies/maruti.png";
// import techfabIndia from "../../../../assets/images/associateCompanies/techfab-india.png";
// import dalmia from "../../../../assets/images/associateCompanies/dalmia.png";
// import everestEqp from "../../../../assets/images/associateCompanies/everest_eqp.png";
import uniflex from "../../../../assets/images/associateCompanies/uniflex.png";
import modylogo from "../../../../assets/images/associateCompanies/modylogo.png";
import kill from "../../../../assets/images/associateCompanies/kill.png";
// import sandvik from "../../../../assets/images/associateCompanies/sandvik.png";

const Associates = () => {
  return (
    <div className="-mt-80 lg:mt-0 pb-8">
      <img
        src={wave}
        alt="wave"
        className="min-w-full relative z-10"
        style={{ marginTop: "-26%" }}
      />
      <div
        className="my-24 relative z-10 space-y-24"
        style={{ marginTop: "-20%" }}
      >
        {/* <ArrowCard
          right={false}
          title="Maruti"
          description="Maruti is engaged in such as shuttering formwork, falsework, scaffolding. Our products are: PVC Water Stops, Bridge Bearing Pads, Pneumatic Air House Pipes, PVC Sheets."
          link="#"
          img={maruti}
        /> */}
        {/* <ArrowCard
          right={true}
          title="Techfab India"
          description="TechFab India offers a wide range of high quality geosynthetic products. Some of our products are: Rubber and PVC Water Stops, Bridge Bearing Pads, Pneumatic Air House Pipes, PVC Sheets."
          link="https://www.techfabindia.com/"
          img={techfabIndia}
        /> */}
        {/* <ArrowCard
          right={false}
          title="Dalmia Cement"
          description="Dalmia is the single unit cement manufacturing company based in India which deals with special purpose cement. We have set up windmills to generate inexpensive and eco-friendly captive power for our plant."
          link="https://www.dalmiacement.com/"
          img={dalmia}
        /> */}
        {/* <ArrowCard
          right={true}
          title="Everest"
          description="'EVEREST' Brand SILICA FUME specialises in superior quality cement sheets, Designer Cement Sheets, heavy duty wall boards, and solid wall panels."
          link="#"
          img={everestEqp}
        /> */}
        <ArrowCard
          right={false}
          title="Uniflex"
          description="Uniflex-Hydraulik GmbH has been developing, manufacturing and selling various types of crimping Machine and Cutting Machines for 40 years."
          link="https://www.uniflex-hydraulics.com/"
          img={uniflex}
        />
        <ArrowCard
          right={true}
          title="Kirlosker"
          description="Silent diesel generator from 2.4 to 1010 KVA."
          link="https://www.uniflex-hydraulics.com/"
          img={kill}
        />
        <ArrowCard
          right={false}
          title="Mody Pumps"
          description="Electrical portable, submersible dewatering pumps, clog pump."
          link="https://www.uniflex-hydraulics.com/"
          img={modylogo}
        />
        {/* <ArrowCard
          right={false}
          title="Sandvik Drilling Tools"
          description="It provides you drilling tools for different machining conditions, materials and application types. Our products are: Small hole drilling, threaded button bits, drill rods, shank adapters, coupling sleeves."
          link="#"
          img={sandvik}
        /> */}
      </div>
    </div>
  );
};

export default Associates;
