import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { QuoteCard } from "../../../utilities/Cards";
// Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "./quote.css";
// Import swiper core and required modules
import SwiperCore, { Pagination, Autoplay } from "swiper/core";

// Image dummy
import brTater from "../../../assets/images/person/BR-tater.png";
import vTater from "../../../assets/images/about/img_2.png";
import viTater from "../../../assets/images/about/vivek-tater.jpg";
import sTater from "../../../assets/images/about/saurav-tater.jpg";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay]);

const QuoteSwiper = () => {
  return (
    <div className="quote-container relative">
      <Swiper
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        loop={true}
        className="h-full quoteSwiper-container shadow-half sm:shadow-xl"
        style={
          window.innerWidth > 1024
            ? { width: "55.62rem", position: "unset" }
            : {
                width: window.innerWidth < 768 ? "23.33rem" : "40rem",
                position: "unset",
              }
        }
      >
        <SwiperSlide className="qote-slide w-full">
          <QuoteCard
            image={brTater}
            name="Mr. Bachhraj Tater"
            position="Chairman of Tater Group"
            quote="Founder/Chairman of the Group. He is one of the prominent figures in Construction & Financial sectors who has been instrumental in taking new ventures to help diversify the group."
          />
        </SwiperSlide>
        <SwiperSlide className="qote-slide w-full">
          <QuoteCard
            image={vTater}
            name="Mr. Vishal Tater"
            position="Director"
            quote="Joined the Group in 2001, he has taken the trading wing of Tater Group to a new height by adding up worldwide prominent brands represented in Nepal. He also is the Director of Gurans Life Insurance Company Ltd. and has been leading our Construction Department under South Asia Infrastructure Pvt. Ltd."
          />
        </SwiperSlide>
        <SwiperSlide className="qote-slide w-full">
          <QuoteCard
            image={viTater}
            name="Mr. Vivek Tater"
            position="Director"
            quote="The former MD of Vishal Plastocab Industries P. Ltd., Mr Vivek Tater Joined group in 2005. He manages the manufacturing units of Cables, Conductors, uPVC pipes & fittings and, HDPE pipes and is also involved in Hydropower development activities of the Group."
          />
        </SwiperSlide>
        <SwiperSlide className="qote-slide w-full">
          <QuoteCard
            image={sTater}
            name="Mr. Saurav Tater"
            position="Director"
            quote="Joined group in 2007, Mr. Saurav Tater has been maintaining Administration & Financial discipline of the group. Also, he has been actively contributing to Business Development."
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default QuoteSwiper;
