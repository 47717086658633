import React from "react";
import { HgBanner, HgAssociates } from "../../../components";
const HgEnt = () => {
  return (
    <>
      <HgBanner />
      <HgAssociates />
    </>
  );
};

export default HgEnt;
