import React from "react";
import AboutBanner from "../../components/About/AboutBanner/AboutBanner";
import Timeline from "../../components/About/Timeline/Timeline";
import AboutNetwork from "../../components/About/AboutNetwork/AboutNetwork";
import MissionAndVision from "../../components/About/MissionAndVision/MissionAndVision";
import CoreValues from "../../components/About/CoreValues/CoreValues";
import ManagementTeam from "../../components/About/ManagementTeam/ManagementTeam";

const About = () => {
  return (
    <div>
      <AboutBanner />
      <Timeline />
      <MissionAndVision />
      <CoreValues />
      <ManagementTeam />
      <AboutNetwork />
    </div>
  );
};

export default About;
