import React from "react";
interface quoteProps {
  name: string;
  position: string;
  quote: string;
  image: string;
}
const QuoteCard = ({ name, position, quote, image }: quoteProps) => {
  return (
    <div style={{}} className="relative w-full">
      <svg
        width="525"
        height="290"
        viewBox="0 0 525 544"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute transform -translate-x-16 hidden lg:block"
      >
        <path
          d="M145.833 450.811C67.5 459.732 15.9722 516.654 0 544H525V0H343.75C338.75 350.454 209.722 446.563 145.833 450.811Z"
          fill="#fff"
        />
      </svg>
      <div
        className="flex flex-col lg:grid sm:gap-5 w-full bg-white"
        style={{ gridTemplateColumns: "1fr 1.6fr" }}
      >
        <img
          src={image}
          alt={`${name}`}
          className="w-auto max-w-96 h-72 md:h-96 lg:h-72 object-cover mb-5 sm:mb-0 sm:object-cover"
        />
        <div className="flex flex-col justify-evenly space-y-0 md:space-y-5 lg:space-y-0 relative z-10 mb-0 md:mb-14 lg:mb-0">
          <div>
            <p className="text-center lg:text-left font-primary font-semibold text-lg md:text-xl lg:text-lg text-black">
              {name}
            </p>
            <p className="text-sm md:text-xl sm:text-base text-center lg:text-left font-primary font-semibold text-primary-900">
              {position}
            </p>
          </div>
          <q className="text-lg italic text-gray-500 mb-10 sm:mb-0 text-center lg:text-left font-primary font-semibold sm:text-lg leading-relaxed mt-0 pr-0 sm:-mt-4 sm:pr-3">
            {quote}
          </q>
        </div>
      </div>
    </div>
  );
};

export default QuoteCard;
