// @ts-nocheck
import React from "react";
import { SingleBrandPage } from "../../components";

const SingleBrand = () => {
  return (
    <div>
      <SingleBrandPage />
    </div>
  );
};

export default SingleBrand;
