// @ts-nocheck
import React, { useState } from 'react'

const AlertComponent = ({ alert  }) => {

    const [Modal, setModal] = useState(true);  //alert model
    const closeModal = () => {
        setModal(false)
    }

    return (
        <div>
            <div className="flex items-center container mx-auto">
                    <div className={Modal ? "pop-Up bg-gray-300 bg-opacity-40 bg-clip-padding  text-blue-50 absolute h-auto w-11/12 md:w-4/5 top-16 shadow-2xl rounded-2xl pb-12 z-50 md:ml-10 " : "hidden" } style={{  backdropFilter:'blur(15px)', minHeight:'50vh'}}>
                        <div >
                            <button onClick={() => closeModal()} className="border-2 bg-gray-400 focus:outline-none hover:bg-primary-900 hover:text-black text-lg font-medium p-3 float-right m-4"><i className="px-3 fas fa-times"></i></button>
                        </div>
                        <div className="pt-20 pb-10 px-5 md:px-24 text-gray-800">
                            <p className=" text-2xl md:text-4xl font-semibold py-10 ">{alert?.acf?.alert_title}</p>
                            <p className="text-lg my-2 "> {alert?.acf?.alert_description} </p>
                        </div> 
                    </div>
                </div>
        </div>
    )
}

export default AlertComponent
