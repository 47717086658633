import React from "react";
import vishalTradeLink from "../../../../assets/images/ownCompanies/vishal_tradelink.png";
import singleBanner from "../../../../assets/images/Business/singleBusinessBanner.png";

const Banner = () => {
  return (
    <div
      className="bg-no-repeat bg-cover h-full"
      style={{
        backgroundImage: `url(${singleBanner})`,
        transform: "translateY(-9.1rem)",
      }}
    >
      <div
        className="container mx-auto grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2"
        style={{
          paddingTop: "11.8rem",
          paddingBottom: "13.8rem",
        }}
      >
        <div className="pt-10">
          <div className="flex space-x-10 items-center">
            <div className="flex flex-col justify-center space-y-3">
              <p className="font-primary text-5xl font-bold uppercase text-gray-700">
                Vishal Trade Link
              </p>
            </div>
          </div>
          <p className="font-primary text-lg font-normal pt-2 text-gray-800">
            Vishal Trade Links provides wholesale distribution and service of
            on-site backup and mobile power generation equipment. Authorized
            dealer of Kirloskar gensets in Nepal, supplying small and
            large-scale operations with power solutions and knowledgeable
            technical support and service.
          </p>
        </div>
        <div className="place-self-center">
          <img src={vishalTradeLink} alt="" className="lg:block hidden" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
