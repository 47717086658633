import React from "react";
import { CompanyCard } from "../../../utilities/Cards";
import { HomeAccordion } from "../../../utilities";
import backgroundImage from "../../../assets/images/companies-bg.svg";
import { data } from "./dummy";

const Companies = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        transform: "translateY(-5rem)",
      }}
      className="bg-no-repeat bg-contain lg:mb-10"
    >
      <div className="container mx-auto block lg:grid">
        {window.innerWidth >= 1024 ? (
          <>
            <div className="lg:grid block lg:grid-cols-3 lg:gap-0 xl:gap-5">
              <div className="hidden lg:space-y-20 lg:block">
                {data.slice(0, 2).map((d, index) => (
                  <CompanyCard
                    id={d.id}
                    key={index}
                    link={d.link}
                    icon={d.icon}
                    title={d.title}
                  />
                ))}
              </div>
              <div className="hidden lg:space-y-20 lg:block">
                {data.slice(2, 4).map((d, index) => (
                  <CompanyCard
                    id={d.id}
                    key={index}
                    icon={d.icon}
                    link={d.link}
                    title={d.title}
                  />
                ))}
              </div>
              <div className="hidden lg:space-y-20 lg:block">
                {data.slice(4, 6).map((d, index) => (
                  <CompanyCard
                    id={d.id}
                    key={index}
                    icon={d.icon}
                    link={d.link}
                    title={d.title}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="block -mt-10">
              <div className="place-self-center">
                {data.map((data) => (
                  <>
                    <HomeAccordion
                      id={data.id}
                      title={data.title}
                      description={data.description}
                      link={data.link}
                      icon={data.icon}
                    />
                  </>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Companies;
