// @ts-nocheck
import { connect } from "react-redux";
import { getTeams } from "../../../Redux/actions/Teams/TeamAction";
import React, { useRef, useEffect } from "react";
import { TestimonialCard } from "../../../utilities";
import arrowRight from "../../../assets/images/arrow_right.svg";
import arrowLeft from "../../../assets/images/arrow_left.svg";
// import swiper slide
import { Swiper, SwiperSlide } from "swiper/react";
// Swiper styles
import "swiper/swiper.min.css";
// Swiper css
import "./managementTestimonial.css";
// Import swiper core and required modules
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper/core";
// install Swiper modules
SwiperCore.use([Navigation, Autoplay, Pagination]);

const ManagementTestimonialSwiper = ({ getTeams, teams, loading }) => {
  const navigationNextRef = useRef(null);
  const navigationPrevRef = useRef(null);
  
  useEffect(() => {
    getTeams();
  },[getTeams])

  return (
    <div className="management-testimonial-container ">
      <div className="flex justify-center items-center space-x-10 my-10">
        <img
          src={arrowLeft}
          alt=""
          ref={navigationPrevRef}
          className="cursor-pointer"
        />
        <img
          src={arrowRight}
          alt=""
          ref={navigationNextRef}
          className="cursor-pointer"
        />
      </div>
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={false}
        spaceBetween={20}
        className="mySwiper"
        grabCursor
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
          disabledClass: "buttonDisable",
        }}
        onSwiper={(swiper) => {
          // Delay execution for the refs to be defined
          setTimeout(() => {
            // Override prevEl & nextEl now that refs are defined
            // @ts-ignore
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = navigationNextRef.current;

            // Re-init navigation
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
      >

        {teams.map((team, index) => (
          <SwiperSlide>
            <TestimonialCard
            key={index}
            name={team.acf.member_name}
            image={team.acf.member_image.url}
            description={team.acf.member_quote}
            />
          </SwiperSlide>
        ))}
        
      </Swiper>
    </div>
  );
};


const mapStateToProps = (state) => ({
  teams: state.TeamsList.teams,
  loading: state.TeamsList.loading,
});

export default connect(mapStateToProps, { getTeams })(ManagementTestimonialSwiper);