import React from "react";
import banner from "../../../../assets/images/Business/banner_background.png";
import techIcon from "../../../../assets/images/Business/techIcon.svg";
import educationPeople from "../../../../assets/images/Business/educationPeople.png";

const Banner = () => {
  return (
    <div
      className="bg-no-repeat bg-cover h-full relative"
      style={
        window.innerWidth < 1440
          ? {
              backgroundImage: `url(${banner})`,
              transform: "translateY(-9.1rem)",
            }
          : {
              backgroundImage: `url(${banner})`,
              transform: "translateY(-9.1rem)",
              paddingBottom: "4rem",
            }
      }
    >
      <div
        className="container mx-auto grid lg:grid-cols-2 grid-cols-1 lg:gap-4 gap-2"
        style={{
          paddingTop: "11.8rem",
          paddingBottom: "13.8rem",
        }}
      >
        <div className="pt-10">
          <div className="flex lg:space-x-10 space-x-2 items-center">
            <img src={techIcon} alt="" className="h-28 w-28" />
            <div className="flex flex-col justify-center space-y-3">
              <p className="font-primary text-2xl font-bold uppercase text-white">
                tater's group
              </p>
              <p className="font-primary text-4xl font-bold uppercase text-white">
                It & education
              </p>
            </div>
          </div>
          <p className="font-primary text-lg font-normal pt-2 text-white">
            Tater Group is working in IT and education sector to digitize
            education system of Nepal and provide IT services to the clients.
          </p>
        </div>
      </div>
      <img
        src={educationPeople}
        alt=""
        className="absolute top-36 right-0 lg:block hidden"
      />
    </div>
  );
};

export default Banner;
