import React from "react";
import Management_bg from "../../../assets/images/about/Management_bg.png";
import Teams from "../../Teams/Teams";

const ManagementTeam = () => {
  return (
    <div
      className="team lg:mt-24 bg-no-repeat bg-cover"
      style={{
        backgroundImage: `url(${Management_bg})`,
      }}
    >
      <div className="pt-32 mx-auto container">
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col justify-center">
            <p className="text-xl font-primary font-semibold capitalize text-red-400 pb-4">
              Tater Group's
            </p>
            <p className="text-2xl lg:text-4xl font-primary font-semibold capitalize text-black pb-4">
              TEAM
            </p>
            <div className="w-48 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
            <p className="font-primary leading-7 lg:text-justify text-black ">
              Tater Group is operated by a dynamic management team which
              comprises of people from diverse professional backgrounds. Each
              member of our team is committed to their roles and
              responsibilities and represents the company’s core values. The
              team spirit is driven by success and consolidates individual
              skills to guide the company through resilient challenges.
            </p>
          </div>
        </div>
        
        <div className="pt-20 pb-0 lg:pb-16">
          <Teams />
        </div>
      </div>
    </div>
  );
};

export default ManagementTeam;
