import React from "react";
import vacancies_bg from "../../../assets/images/career/vacancies_bg.svg";
import wave_bg from "../../../assets/images/career/wave_bg.svg";
import Accordion from "../Accordion/Accordion";

const Vacancies = () => {
  return (
    <>
      <div>
        <img
          className="w-full object-cover relative"
          src={vacancies_bg}
          alt="vacancies_bg"
          style={{ marginTop: window.innerWidth > 1024 ? "-27%" : "0%" }}
        />
        <img className="absolute top-3/4 opacity-50" src={wave_bg} alt="" />
        <div>
          <div
            className="flex  flex-col justify-center mb-64 container mx-auto"
            style={{ marginTop: "-38%" }}
          >
            <div className="relative">
              <p className="text-3xl font-primary font-bold uppercase text-skin-100 pb-4">
                Vacancies
              </p>
              <div className="w-48 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
            </div>
            {/* Accordion is used */}
            <Accordion />
          </div>
        </div>
      </div>
    </>
  );
};

export default Vacancies;
