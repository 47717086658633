import React from "react";
import { NavLink } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

interface IProps {
  title?: string;
  lists?: any;
  toggle?: any;
}

const AccordionNav = ({ title, lists, toggle }: IProps) => {
  return (
    <Accordion className="outline-none border-none" allowZeroExpanded={true}>
      <AccordionItem className="flex flex-col">
        <AccordionItemHeading>
          <AccordionItemButton className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900">
            {title}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="place-self-center">
          <div className="py-1">
            {
              //@ts-ignore
              lists.map((list) => (
                <div key={list.id}>
                  <NavLink
                    to={`/business${list.url}`}
                    className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
                    activeClassName="text-primary-900"
                    onClick={toggle}
                  >
                    {list.title}
                  </NavLink>
                </div>
              ))
            }
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionNav;
