import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";

interface IProps {
  id: string;
  image: string;
  title: string;
  date: string;
  description: string;
  small: boolean;
  company: string;
}

const BlogCard = ({
  id,
  image,
  title,
  date,
  description,
  small,
  company,
}: IProps) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  return (
    <div
      className={`min-h-0 md:min-h-full mb-5 md:mb-0 bg-white rounded-xl relative shadow-md transition-all ease-in-out duration-300 transform hover:shadow-lg hover:-translate-y-2 hover:text-primary-900`}
    >
      <NavLink to={`/blogs/${id}`}>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <h1>Loading</h1>
          </div>
        ) : (
          <>
            <div
              className={`absolute ${
                small ? "w-10 h-10 text-xs font-medium" : "w-24 h-24"
              } shadow-lg px-5 text-center flex justify-center items-center right-2 top-2 rounded-full bg-gradient-to-br from-gradient-1 to-red-400 text-white font-primary font-semibold`}
            >
              {moment(date).format("Do MMMM")}
            </div>
            <img
              src={image}
              alt=""
              className={
                small
                  ? `rounded-tr-xl rounded-tl-xl h-44 w-full object-cover`
                  : `rounded-tr-xl rounded-tl-xl h-96 md:h-96 md:w-full lg:w-auto`
              }
            />
            <div className="px-4">
              <div
                className={
                  small
                    ? `ml-1 rounded-full bg-gradient-to-br from-gradient-1 to-red-400 w-40 text-white font-primary font-semibold text-sm py-2 transform -translate-y-5`
                    : `ml-4 rounded-full bg-gradient-to-br from-gradient-1 to-red-400 w-64 text-white font-primary font-semibold text-sm py-3 transform -translate-y-5`
                }
              >
                <p className="text-center">{company}</p>
              </div>
              <div>
                <p
                  className={
                    small
                      ? `font-primary font-semibold text-md`
                      : `font-primary font-semibold text-2xl`
                  }
                >
                  {title}
                </p>
                <div className="font-primary text-sm py-3">{description}</div>
              </div>
            </div>
          </>
        )}
      </NavLink>
    </div>
  );
};

export default BlogCard;
