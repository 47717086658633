/* eslint-disable import/no-anonymous-default-export */
import { GET_ASSOCIATE, ASSOCIATE_ERROR } from "../../types";
const initialState = {
loading: true,
  associate: [],
  error: "",
};

interface AssociateReducerProps {
    type: string;
    payload: string;
  }

export default (state = initialState,  { type, payload }: AssociateReducerProps) => {
  switch (type) {
    case GET_ASSOCIATE:
      return { ...state, associates: payload, loading: false };
    case ASSOCIATE_ERROR:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

