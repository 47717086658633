import React from "react";
import { ManufacturingBanner, ManufacturingSectors } from "../../../components";
const Manufacturing = () => {
  return (
    <>
      <ManufacturingBanner />
      <ManufacturingSectors />
    </>
  );
};

export default Manufacturing;
