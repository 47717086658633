import React from "react";
import { ProductSwiper } from "../../Swiper";
import wave from "../../../assets/images/Products/landing_banner1.svg";

const Banner = () => {
  return (
    <div
      className="bg-cover -mb-20 bg-no-repeat h-1/2 lg:h-screen"
      style={
        window.innerWidth > 640
          ? {
              transform: `translateY(-9.1rem)`,
              backgroundImage: `url(${wave})`,
              // height: window.innerWidth < 1024 ? "40vh" : "80vh",
              height:
                window.innerWidth <= 1350
                  ? "40vh"
                  : window.innerWidth < 1500
                  ? "80vh"
                  : "67vh",
            }
          : {
              transform: `translateY(-9.1rem)`,
              backgroundImage: `url(${wave})`,
              height: "56vh",
            }
      }
    >
      <div
        className="pb-14 md:pb-0"
        style={{ paddingTop: "11rem", marginBottom: "-9rem" }}
      >
        <ProductSwiper />
      </div>
    </div>
  );
};

export default Banner;
