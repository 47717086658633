//@ts-nocheck
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { RootLayout } from "./layouts";
// import { Alert } from "./components";
import Messenger from "./components/Messenger/Messenger";
import {
  Home,
  Contact,
  About,
  Career,
  Trading,
  Manufacturing,
  Banking,
  Construction,
  Energy,
  Technology,
  VishalTrade,
  HgEnt,
  Blogs,
  SingleBlog,
  Products,
  SauravInt,
  SingleBrand,
} from "./pages";

function App() {
  return (
    <BrowserRouter>
      <RootLayout>
        {/* <Alert /> */}
        <Route path="/" exact component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/products" component={Products} />
        <Route path="/products/:id" component={SingleBrand} />

        <Route path="/career" component={Career} />
        <Route path="/contact" component={Contact} />
        <Route path="/business/trading" exact component={Trading} />
        <Route
          path="/business/trading/vishal_tradelink"
          component={VishalTrade}
        />
        <Route path="/business/trading/hg_ent" component={HgEnt} />
        <Route
          path="/business/trading/saurav_international"
          component={SauravInt}
        />
        <Route path="/business/manufacturing" component={Manufacturing} />
        <Route path="/business/banking" component={Banking} />
        <Route path="/business/construction" component={Construction} />
        <Route path="/business/energy" component={Energy} />
        <Route path="/business/it_edu" component={Technology} />
        <Route path="/blog_media" component={Blogs} />
        <Route path="/blogs/:id" component={SingleBlog} />
      </RootLayout>
      <Messenger />
    </BrowserRouter>
  );
}

export default App;
