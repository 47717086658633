// @ts-ignore
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { QuoteModal } from "../../HomeComponents";
import { MenuIcon } from "@heroicons/react/solid";
import DropDown from "./DropDown";
import Sidebar from "./Sidebar";

import logo from "../../../assets/images/tater_text_primary.png";
import logo_mobile from "../../../assets/images/tater_primary.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [applyQuote, setApplyQuote] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const { pathname } = useLocation();
  // Business list
  const lists = [
    {
      id: 1,
      url: "/trading",
      title: "Trading",
    },
    {
      id: 2,
      url: "/manufacturing",
      title: "Manufacturing",
    },
    {
      id: 3,
      url: "/banking",
      title: "Banking",
    },
    {
      id: 4,
      url: "/construction",
      title: "Construction",
    },
    {
      id: 5,
      url: "/energy",
      title: "Energy",
    },
    {
      id: 6,
      url: "/it_edu",
      title: "It & Education",
    },
  ];
  return (
    <>
      <Sidebar toggle={toggle} isOpen={isOpen} />
      <div className="sticky top-0 z-20">
        <div className="container py-2 mx-auto flex justify-between items-center bg-gray-50 md:py-3 rounded-full shadow-xl">
          <NavLink to="/">
            <img
              src={logo}
              alt="tater_logo"
              className="h-14 w-auto hidden md:block"
            />
            <img
              src={logo_mobile}
              alt="tater_logo"
              className="h-10 w-auto md:hidden"
            />
          </NavLink>
          <div className="hidden md:space-x-4 xl:space-x-8 lg:block">
            <NavLink
              to="/"
              exact
              className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
              activeClassName="text-primary-900"
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
              activeClassName="text-primary-900"
            >
              About
            </NavLink>

            <DropDown title="Business Sector" lists={lists} />
            <NavLink
              to="/products"
              className={`font-primary text-sm font-semibold uppercase ${
                pathname.includes("products" || "brand")
                  ? "text-primary-900"
                  : "text-gray-500"
              } transition-all ease-in-out duration-300 hover:text-primary-900`}
              activeClassName="text-primary-900"
            >
              Products
            </NavLink>
            <NavLink
              to="/blog_media"
              className={`font-primary text-sm font-semibold uppercase ${
                pathname.includes("blogs")
                  ? "text-primary-900"
                  : "text-gray-500"
              } transition-all ease-in-out duration-300 hover:text-primary-900`}
              activeClassName="text-primary-900"
            >
              Blog/News
            </NavLink>
            <NavLink
              to="/career"
              className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
              activeClassName="text-primary-900"
            >
              Career
            </NavLink>
            <NavLink
              to="/contact"
              className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
              activeClassName="text-primary-900"
            >
              Contact us
            </NavLink>
            <span
              className="font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900 pointer cursor-pointer"
              onClick={() => setApplyQuote(true)}
            >
              Get A Quote
            </span>
            {applyQuote && <QuoteModal setApplyQuote={setApplyQuote} />}
          </div>
          <div className="lg:hidden xl:block">
            {/* Sider bar */}
            <MenuIcon
              className="w-10 h-10 lg:hidden text-gray-700"
              onClick={toggle}
            />
            {/* <-----Sidebar end -----> */}
            <ul className="hidden xl:flex space-x-10 pr-3">
              <li className="text-2xl transform hover:-translate-y-1  hover:text-blue-600 transition ease-in-out duration-150">
                <a href="https://facebook.com">
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li className="text-2xl transform hover:-translate-y-1 hover:text-green-500 transition ease-in-out duration-150">
                <a href="https://whatsapp.com">
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
