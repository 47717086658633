// @ts-nocheck
import { connect } from 'react-redux'
import { getAssociate } from '../../../Redux/actions/Associate/AssociateAction';
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import AssociateSwiperSkeleton from './AssociateSwiperSkeleton';

// Import swiper styles
import "swiper/swiper.min.css";
// import from swiper core
import SwiperCore, { Autoplay } from "swiper/core";

// init swiper module
SwiperCore.use([Autoplay]);

const AssociateSwiper = ({ getAssociate, associates, loading }) => {

  useEffect(() => {
    getAssociate()
  }, [getAssociate])

  return (
    <Swiper
      slidesPerView={5}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
        reverseDirection: true,
      }}
      loop={true}
      spaceBetween={30}
      freeMode={true}
      className="associate-container"
      style={{ marginLeft: "unset", marginRight: "unset" }}
    >
      { loading ? (
        <div className="">
          <AssociateSwiperSkeleton />
        </div>
      ): (
        associates && associates.map((associate, index) => (
          <SwiperSlide className="flex justify-center items-center" key={index}>
        <img
          src={associate?.acf?.logo?.url}
          alt=""
          className="object-contain h-24 w-full"
        />
      </SwiperSlide>
        ))
      )
      }
    </Swiper>
  );
};

const mapStateToProps = (state) => ({
  associates: state.AssociateList.associates,
  loading: state.AssociateList.loading
 })
 
 export default connect(mapStateToProps, { getAssociate })(AssociateSwiper)
