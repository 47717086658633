import React from "react";
import { SIBanner, SIAssociates } from "../../../components";
const  SauravInt = () => {
  return (
    <>
      <SIBanner />
      <SIAssociates />
    </>
  );
};

export default SauravInt;
