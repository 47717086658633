import React from "react";
import { ArrowCard } from "../../../../utilities";
import wave from "../../../../assets/images/Business/wave.svg";
import saiIcon from "../../../../assets/images/ownCompanies/sai.png";

const Sectors = () => {
  return (
    <div
      className="-mt-80 lg:mt-0 pb-8"
      style={window.innerWidth > 1440 ? { marginTop: "4rem" } : {}}
    >
      <img
        src={wave}
        alt="wave"
        className="min-w-full relative z-10"
        style={{ marginTop: "-29%" }}
      />
      <div
        className="my-24 relative z-10 space-y-24"
        style={
          window.innerWidth > 1440
            ? { marginTop: "-28%" }
            : { marginTop: "-20%" }
        }
      >
        <ArrowCard
          right={false}
          title="South Asian infrastructure"
          description="The South Asian Infrastructure (Pvt.) Ltd. a Private Limited Company is established with an objective to render complete solution in the Infrastructure Development and Hydropower Construction sector in a single door meeting standard."
          link="https://southasianinfrastructure.com/"
          img={saiIcon}
        />
      </div>
    </div>
  );
};

export default Sectors;
