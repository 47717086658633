import React, { useState } from "react";
import landingBg from "../../../assets/images/landing-bg.svg";
import building from "../../../assets/images/building.png";
import { BannerSwiper } from "../../Swiper";
import { VideoModal } from "../../HomeComponents";

const HomePageBanner = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && <VideoModal isOpen={open} setOpen={setOpen} />}
      <div
        className="transform bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${landingBg})`,
          transform:
            window.innerWidth > 768
              ? `translateY(-9.1rem)`
              : `translateY(-11rem)`,
          backgroundPositionY: window.innerWidth > 768 ? `none` : `-10rem`,
          backgroundPositionX: window.innerWidth > 768 ? `none` : `-7rem`,
        }}
      >
        <div
          className="container mx-auto block lg:grid"
          style={
            window.innerWidth > 768
              ? {
                  paddingTop: "11.8rem",
                  gridTemplateColumns:
                    window.innerWidth > 1024 ? "1fr .3fr" : "1fr .1fr",
                  paddingBottom: "13.8rem",
                }
              : {
                  gridTemplateColumns: "1fr",
                  paddingTop: "11.8rem",
                  paddingBottom: "13.8rem",
                  marginBottom: "-11rem",
                }
          }
        >
          {/* This is slider part */}
          <div className="w-full xl:w-96 home-banner">
            <BannerSwiper />
          </div>
          {/* Desktop version */}
          <div className="relative transform xl:translate-y-28 translate-y-24 xl:-translate-x-14 hidden lg:block">
            <div
              className="bg-no-repeat flex justify-center items-center"
              style={{
                backgroundImage: `url(${building})`,
                borderTopLeftRadius: "8.75rem",
                borderBottomRightRadius: "8.75rem",
                height: "27.5rem",
                width: "23.25rem",
              }}
            >
              <div
                className="h-full w-full bg-gray-400 bg-opacity-60 absolute transform translate-x-10 translate-y-8"
                style={{
                  zIndex: -1,
                  borderTopLeftRadius: "8.75rem",
                  borderBottomRightRadius: "8.75rem",
                  height: window.innerWidth === 1024 ? "70%" : "100%",
                }}
              ></div>
              <div
                className="bg-primary-600 rounded-full w-28 h-28 flex justify-center items-center cursor-pointer transition-all ease-in-out duration-300 hover:h-24 hover:w-24"
                onClick={() => setOpen(true)}
              >
                <svg
                  width="86"
                  height="86"
                  viewBox="0 0 78 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="39" cy="39" r="39" fill="white" />
                  <path
                    d="M55 39.4647L31.803 52.8575L31.803 26.0719L55 39.4647Z"
                    fill="#FF9175"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* Mobile and tablet version */}
          <div className="relative block transform ml-32 translate-y-10 md:ml-80 md:translate-y-20 place-self-auto lg:place-self-center lg:hidden">
            <div
              className="bg-primary-600 rounded-full w-28 h-28 flex justify-center items-center cursor-pointer transition-all ease-in-out duration-300 hover:h-24 hover:w-24"
              onClick={() => setOpen(true)}
            >
              <svg
                width="86"
                height="86"
                viewBox="0 0 78 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="39" cy="39" r="39" fill="white" />
                <path
                  d="M55 39.4647L31.803 52.8575L31.803 26.0719L55 39.4647Z"
                  fill="#FF9175"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageBanner;
