import React from "react";
import hgIcon from "../../../../assets/images/ownCompanies/hg_ent.png";
import singleBanner from "../../../../assets/images/Business/singleBusinessBanner.png";

const Banner = () => {
  return (
    <div
      className="bg-no-repeat bg-cover h-full"
      style={{
        backgroundImage: `url(${singleBanner})`,
        transform: "translateY(-9.1rem)",
      }}
    >
      <div
        className="container mx-auto grid lg:grid-cols-2 grid-cols-1"
        style={{
          paddingTop: "11.8rem",
          paddingBottom: "13.8rem",
        }}
      >
        <div className="pt-10">
          <div className="flex space-x-10 items-center">
            <div className="flex flex-col justify-center space-y-3">
              <p className="font-primary text-5xl font-bold uppercase text-gray-700">
                H.G. ENT.
              </p>
            </div>
          </div>
          <p className="font-primary text-lg font-normal pt-2 text-gray-800">
            H.G. Ent. deals with a wide range of construction products like
            cutting-edge machines, and equipment for applications of all scales.
            It offers sales and comprehensive after-service for concrete
            batching, hydro power, road and heavy equipment, construction
            chemicals, and agricultural products.
          </p>
        </div>
        <div className="place-self-center">
          <img src={hgIcon} alt="" className="lg:block hidden" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
