// @ts-nocheck
import React, { useState } from "react";
import { connect } from "react-redux";
import { submitContact } from "../../Redux";

import call_icon from "../../assets/images/career/1call_icon.png";
import mail_icon from "../../assets/images/career/2mail_icon.png";
import fb_icon from "../../assets/images/career/2fb_icon.png";
import linkedIn_icon from "../../assets/images/career/linkedIn_icon.png";
import location_icon from "../../assets/images/career/location_icon.png";

interface IProps {
  submitContact?: any;
}

const ContactForm = ({ submitContact }: IProps) => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    topic: "",
    message: "",
  });
  const { full_name, email, phone, topic, message } = formData;

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submitContact({ data: formData }); //Here will be form submitted
  };

  return (
    <div
      className="pb-20"
      style={{
        transform: `translateY(-28rem)`,
        marginBottom: "-32rem",
      }}
    >
      {/* Form section */}

      <section className="Fsection mt-10 lg:-mt-72 xl:-mt-96 bg-transparent rounded-md">
        <div className="lg:mx-auto lg:container overflow-hidden">
          <div className="Fcontainer py-10">
            <div
              className="FcontactInfo bg-primary-900 rounded-md lg:block my-10"
              style={
                window.innerWidth < 1024
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <p className="font-primary uppercase font-semibold">Contact Us</p>
              <ul className="info font-roboto tracking-wider">
                <li>
                  <span>
                    <img className="h-1/2" src={call_icon} alt="" />
                  </span>
                  <span> +977-01-5100374 / +977-01-5100413</span>
                </li>
                <li>
                  <span>
                    <img src={mail_icon} alt="" />
                  </span>
                  <span>info@tater-group.com</span>
                </li>
                <li>
                  <span>
                    <img src={fb_icon} alt="" />
                  </span>
                  <span> https://facebook.com/tater-group</span>
                </li>
                <li>
                  <span>
                    <img src={linkedIn_icon} alt="" />
                  </span>
                  <span> https://linkedin.com/tater-group</span>
                </li>
                {/* <li>
                  <span>
                    <img src={watsap_icon} alt="" />
                  </span>
                  <span> +977 9812345678</span>
                </li> */}
                <li>
                  <span>
                    <img src={location_icon} alt="" />
                  </span>
                  <span>Balkumari Bridge, Kathmandu, Nepal</span>
                </li>
              </ul>
            </div>
            <div className="FcontactForm shadow-md lg:shadow-full rounded-lg">
              <form className=" relative mr-3 font-roboto tracking-wider text-gray-700">
                <div className="relative form_item my-1">
                  <label
                    className="w-full text-base font-medium mb-1"
                    htmlFor="Name"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="full_name"
                    value={full_name}
                    onChange={(e) => onChangeHandler(e)}
                    placeholder="Eg: John Doe"
                    className=" relative font-medium text-base w-full h-14 p-5 mb-4 outline-none shadow border-2 border-gray-100 focus:border-gray-200 rounded-lg"
                  />
                </div>

                <div className="form_item mt-3">
                  <label
                    className="w-full text-base font-medium mb-1"
                    htmlFor="Email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => onChangeHandler(e)}
                    placeholder="Eg: contact@example.com"
                    className="font-medium shadow text-gray-700 text-base w-full h-14 p-5 mb-4 outline-none border-2 border-gray-100 focus:border-gray-200 rounded-lg"
                  />
                </div>

                <div className="grid grid-cols-2 gap-x-2 px-2">
                  <div className="form_item mt-3">
                    <label
                      className="w-full text-base font-medium mb-1"
                      htmlFor="Phone"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      id="phone"
                      value={phone}
                      onChange={(e) => onChangeHandler(e)}
                      placeholder="Eg: 981234567"
                      className="font-medium shadow text-gray-700 pt-4  text-base w-full h-14 p-5  mb-4 outline-none border-2 border-gray-100 focus:border-gray-200 rounded-lg"
                    />
                  </div>

                  <div className="form_item mt-3">
                    <label className=" text-base font-medium" htmlFor="Topic">
                      Topic
                    </label>
                    <input
                      type="text"
                      id="topic"
                      value={topic}
                      onChange={(e) => onChangeHandler(e)}
                      placeholder="Eg: Production Info"
                      className="font-medium shadow text-gray-700 text-base w-full h-14 p-5 mb-4 outline-none border-2 border-gray-100 focus:border-gray-200 rounded-lg"
                    />
                  </div>
                </div>
                <div className="form_item mt-3">
                  <label
                    className="w-full text-base font-medium"
                    htmlFor="Message"
                  >
                    Message
                  </label>
                  <textarea
                    name=""
                    id="message"
                    value={message}
                    onChange={(e) => onChangeHandler(e)}
                    placeholder="Message"
                    className="font-medium shadow text-gray-700 text-base w-full h-48 md:h-58 p-5 mb-2 outline-none border-2 border-gray-100 focus:border-gray-200 rounded-lg"
                  ></textarea>
                </div>

                <div className="mt-2">
                  <input
                    type="submit"
                    onClick={(e) => onSubmit(e)}
                    name="Submit"
                    className="cursor-pointer md:px-4 mt-3 w-full rounded-full bg-gradient-to-r from-gradient-1 to-gradient-2 text-white font-primary text-xl font-semibold shadow-md transition-all ease-in-out duration-300 hover:shadow-xl transform hover:-translate-y-1"
                    style={{ height: "3rem" }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default connect(null, { submitContact })(ContactForm);
