import React from "react";
import { ArrowCard } from "../../../../utilities";
import wave from "../../../../assets/images/Business/wave.svg";
import sunrise from "../../../../assets/images/ownCompanies/sunrise.png";

const Sectors = () => {
  return (
    <div
      className="-mt-80 lg:mt-0 pb-8"
      style={window.innerWidth > 1440 ? { marginTop: "4rem" } : {}}
    >
      <img
        src={wave}
        alt="wave"
        className="min-w-full relative z-10"
        style={{ marginTop: "-29%" }}
      />
      <div
        className="my-24 relative z-10 space-y-24"
        style={
          window.innerWidth > 1440
            ? { marginTop: "-28%" }
            : { marginTop: "-20%" }
        }
      >
        <ArrowCard
          right={false}
          title="Sunrise Bank Limited"
          description="In an economy driven by appetite for success, Sunrise Bank Limited is Rising to Serve by defining new levels of services and products. As a bank founded by reputed entrepreneurs, we understand the needs of a growing economy."
          link="https://www.sunrisebank.com.np/"
          img={sunrise}
        />
      </div>
    </div>
  );
};

export default Sectors;
