import React, { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface DropDownProps {
  title: string;
  lists: any;
}

const DropDown = ({ title, lists }: DropDownProps) => {
  const { pathname } = useLocation();
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className={`font-primary text-sm font-semibold uppercase ${
                pathname.includes("/business")
                  ? "text-primary-900"
                  : "text-gray-500"
              } transition-all ease-in-out duration-300 hover:text-primary-900 outline-none border-none focus:outline-none`}
            >
              {title}
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute mt-8 -left-12 w-56 text-center rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                {
                  //@ts-ignore
                  lists.map((list) => (
                    <Menu.Item key={list.id}>
                      {({ active }) => (
                        <NavLink
                          to={`/business${list.url}`}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-500",
                            "block px-4 py-2 font-primary text-sm font-semibold uppercase text-gray-500 transition-all ease-in-out duration-300 hover:text-primary-900"
                          )}
                          activeClassName="text-primary-900"
                        >
                          {list.title}
                        </NavLink>
                      )}
                    </Menu.Item>
                  ))
                }
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default DropDown;
