import React from "react";
import contact_bg from "../../assets/images/contact/contact_bg.svg";

const Map = () => {
  return (
    <div>
      <div
        className="contact-map justify-self-center"
        style={{
          height: "56rem",
          transform: `translateY(-11rem)`,
          marginBottom: "-11rem",
        }}
      >
        <iframe
          title="test"
          className=" h-full w-full"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14133.637312915464!2d85.3426981!3d27.673741!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9eeb4adb11b9aef0!2sTater%20Group!5e0!3m2!1sen!2snp!4v1625131151088!5m2!1sen!2snp"
          width="100%"
          height="auto"
          style={{ border: "0" }}
          loading="lazy"
        ></iframe>
      </div>
      <div
        className=""
        style={{
          transform: `translateY(-12rem)`,
          // marginBottom: "-12rem",
        }}
      >
        <img src={contact_bg} alt="" className="" />
      </div>
    </div>
  );
};

export default Map;
