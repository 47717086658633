import React from "react";
import career_banner_bg from "../../../assets/images/career/career_banner_bg.svg";
import banner_img from "../../../assets/images/career/banner_img.png";
import banner_icon from "../../../assets/images/career/banner_icon.png";

const CareerPageBanner = () => {
  return (
    <>
      <div
        className="flex items-center lg:justify-center bg-no-repeat bg-cover"
        style={
          window.innerWidth < 1024
            ? {
                backgroundImage: `url(${career_banner_bg})`,
                transform: `translateY(-9rem)`,
                marginBottom: "-9rem",
                height: window.innerWidth > 1024 ? "100vh" : "80vh",
              }
            : {
                backgroundImage: `url(${career_banner_bg})`,
                transform: `translateY(-9rem)`,
                marginBottom: "-9rem",
                height: window.innerWidth > 1440 ? "80vh" : "100vh",
              }
        }
      >
        <div className="block lg:grid lg:grid-cols-2">
          <div className="flex w-screen mt-32 lg:mt-0 lg:w-auto flex-col lg:flex-row mx-auto container items-center mb-20">
            <img
              className="h-24 w-24 place-self-start lg:place-self-auto"
              src={banner_icon}
              alt="banner_icon"
            />
            <div className="text-white w-full font-semibold mt-4 pl-0 lg:pl-4">
              <p className="text-2xl font-primary">CAREER WITH</p>
              <p
                className="font-bold mt-4 font-primary text-4xl"
                style={{ width: window.innerWidth > 1024 ? "63rem" : "100%" }}
              >
                TATER GROUP
              </p>
            </div>
          </div>
          <div
            className="mt-12 -mr-2 transform translate-x-5 ml-0 lg:-ml-36 hidden lg:block"
            style={
              window.innerWidth > 1024
                ? { width: "49.9rem", height: "38.25rem" }
                : { width: "39.5rem", height: "31.25rem" }
            }
          >
            <img src={banner_img} alt="banner_img" className="h-full" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerPageBanner;
