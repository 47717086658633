import React from "react";
import background from "../../../assets/images/valuable_bg.svg";
// import { QuoteSwiper } from "../../Swiper";
import ChairmanMessage from "../../Teams/ChairmanMessage";
const ValuableQuotes = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundPositionY: "-2rem",
        // height: "50rem",
      }}
      className="bg-no-repeat bg-cover transform -translate-y-10"
    >
      <div className="container mx-auto flex flex-col w-full h-full justify-center items-center relative">
        <p
            className="font-primary text-2xl -mb-16 lg:mb-0 sm:text-3xl font-semibold text-center pt-10 lg:pt-64 pb-14"
          style={
            window.innerWidth > 1024
              ? { width: "28.12rem" }
              : { width: window.innerWidth < 768 ? "100%" : "28rem" }
          }
        >
          Chairman Message
        </p>
            <ChairmanMessage />
      </div>
    </div>
  );
};

export default ValuableQuotes;
