import React from "react";
import { CareerPageBanner } from "../../components";
import Form from "../../components/Career/Form/Form";
import Vacancies from "../../components/Career/Vacancies/Vacancies";

const Career = () => {
  return (
      <div>
        <CareerPageBanner />
        <Vacancies />
        <Form />
      </div>
  );
};

export default Career;
