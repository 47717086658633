// @ts-nocheck
import React, { useState } from "react";
import { connect } from "react-redux";
import { submitCareer } from "../../../Redux/actions";

import "./form.css";
import call_icon from "../../../assets/images/career/1call_icon.png";
import mail_icon from "../../../assets/images/career/2mail_icon.png";
import fb_icon from "../../../assets/images/career/2fb_icon.png";
import linkedIn_icon from "../../../assets/images/career/linkedIn_icon.png";
import location_icon from "../../../assets/images/career/location_icon.png";
import attachment_icon from "../../../assets/images/career/attachment-icon.png";
interface IProps {
  submitCareer?: any;
}

const Form = ({ submitCareer }: IProps) => {
  const [filename, setFilename] = useState("Attach Resume");
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [file, setFile] = useState("");
  const { full_name, email, phone, message } = formData;
  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      ...formData,
      file,
    };
    submitCareer({ data: finalData }); // here will be submit action triggred
  };
  return (
    <div className="-mt-36">
      <div className=" mx-auto lg:my-12 lg:mt-24 pb-0 container">
        <p className="text-2xl lg:text-3xl relative font-primary font-bold capitalize text-skin-100 pb-4">
          DROP YOUR RESUME
        </p>
        <div className="relative w-48 h-1 bg-gradient-to-r from-red-50 to-red-400 mb-4" />
      </div>
      {/* Form section */}

      <section className="Fsection -mt-14 lg:mt-0 mb-10 lg:mb-0 items-start">
        <div className="lg:mx-auto lg:container overflow-hidden mt-0 sm:-mt-40 lg:mt-0">
          <div className="Fcontainer">
            <div
              className="FcontactInfo bg-primary-900"
              id="career_contact"
              style={
                window.innerWidth < 1024
                  ? { display: "none" }
                  : { display: "flex" }
              }
            >
              <p className="font-primary uppercase font-semibold">Contact Us</p>
              <ul className="info font-roboto tracking-wider">
                <li className="hover:text-gray-100 transition-all ease-in-out duration-300 cursor-pointer">
                  <span>
                    <img className="h-1/2" src={call_icon} alt="" />
                  </span>
                  <span> +977-01-5100374 / +977-01-5100413</span>
                </li>
                <li className="hover:text-gray-100 transition-all ease-in-out duration-300 cursor-pointer">
                  <span>
                    <img src={mail_icon} alt="" />
                  </span>
                  <span>info@tater-group.com</span>
                </li>
                <li className="hover:text-gray-100 transition-all ease-in-out duration-300 cursor-pointer">
                  <span>
                    <img src={fb_icon} alt="" />
                  </span>
                  <span> https://facebook.com/tater-group</span>
                </li>
                <li className="hover:text-gray-100 transition-all ease-in-out duration-300 cursor-pointer">
                  <span>
                    <img src={linkedIn_icon} alt="" />
                  </span>
                  <span> https://linkedin.com/tater-group</span>
                </li>
                <li>
                  <span>
                    <img src={location_icon} alt="" />
                  </span>
                  <span>Balkumari Bridge, Kathmandu, Nepal</span>
                </li>
              </ul>
            </div>
            <div className="FcontactForm shadow-md lg:shadow-full rounded-lg mt-5 mb-14">
              <form className=" relative mr-3 ">
                <div className="relative form_item my-1">
                  <label
                    className="tracking-wider w-full text-base font-medium font-roboto mb-1"
                    htmlFor="Name"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="full_name"
                    placeholder="Eg: John Doe"
                    value={full_name}
                    onChange={(e) => onChangeHandler(e)}
                    className="relative text-base font-roboto w-full h-14 p-5 mb-4 outline-none shadow border-2 border-gray-100 focus:border-gray-200 rounded-lg placeholder-gray-300 font-medium tracking-widest"
                  />
                </div>

                <div className="form_item mt-3">
                  <label
                    className="tracking-wider w-full text-base font-medium font-roboto mb-1"
                    htmlFor="Email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => onChangeHandler(e)}
                    placeholder="Eg: user@example.com"
                    className="tracking-widest placeholder-gray-300 text-base font-roboto w-full h-14 p-5 mb-4 outline-none shadow border-2 border-gray-100 focus:border-gray-200 rounded-lg"
                  />
                </div>
                <div className="form_item mt-3">
                  <label
                    className="tracking-wider w-full text-base font-medium font-roboto mb-1"
                    htmlFor="phone"
                  >
                    Phone
                  </label>
                  <input
                    type="number"
                    id="phone"
                    value={phone}
                    onChange={(e) => onChangeHandler(e)}
                    placeholder="Eg: 9800000001"
                    className="tracking-widest placeholder-gray-300 text-base font-roboto w-full h-14 p-5 mb-4 outline-none shadow border-2 border-gray-100 focus:border-gray-200 rounded-lg"
                  />
                </div>
                <div className="form_item mt-3">
                  <label
                    className="font-roboto tracking-wider w-full text-base font-medium"
                    htmlFor="Name"
                  >
                    Message
                  </label>
                  <textarea
                    name=""
                    id="message"
                    value={message}
                    onChange={(e) => onChangeHandler(e)}
                    placeholder="Message"
                    className="font-roboto tracking-widest placeholder-gray-300 text-base w-full h-48 md:h-58 p-5 mb-2 outline-none shadow border-2 border-gray-100 focus:border-gray-200 rounded-lg"
                  ></textarea>
                </div>

                <div className="grid grid-cols-2 gap-x-8 mt-2">
                  <label className="font-roboto tracking-wider cursor-pointer text-base w-full p-2 mt-3 outline-none shadow border-2 border-gray-200 focus:border-gray-200 rounded-lg">
                    <div className="flex items-center justify-center space-x-5 relative">
                      <img
                        src={attachment_icon}
                        alt=""
                        className="hidden md:inline-block ml-5"
                      />
                      <p className="mr-5 font-roboto h-full text-base font-medium text-gray-300 tracking-widest">
                        {filename}
                      </p>
                    </div>
                    <input
                      type="file"
                      className="hidden"
                      onChange={(e) => {
                        setFilename(e.target.files[0].name);
                        onChangeFile(e);
                      }}
                    />
                  </label>

                  <input
                    type="submit"
                    name="Submit"
                    onClick={(e) => onSubmit(e)}
                    className="cursor-pointer md:px-4 mt-3 rounded-md bg-gradient-to-r from-gradient-1 to-gradient-2 text-white font-roboto tracking-wider text-lg font-semibold shadow-md transition-all ease-in-out duration-300 hover:shadow-xl transform hover:-translate-y-1"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default connect(null, { submitCareer })(Form);
