import tradingIcon from "../../../assets/images/icons/handshake.svg";
import manufacturingIcon from "../../../assets/images/icons/Manufacturing.svg";
import bankingIcon from "../../../assets/images/icons/Banking_icon.svg";
import constructionIcon from "../../../assets/images/icons/construction.svg";
import energyIcon from "../../../assets/images/icons/energy.svg";
import technologyIcon from "../../../assets/images/icons/technology.svg";
export const data = [
  {
    id: 1,
    icon: manufacturingIcon,
    title: "Manufacturing",
    description:
      "Tater Group is manufacturing various construction materials corresponding to the customer’s needs and specifications.",
    link: "/business/manufacturing",
  },
  {
    id: 2,
    icon: constructionIcon,
    title: "Construction",
    description:
      "Tater Group is working in the construction field with an objective to render complete solution in the Infrastructure Development and Hydropower Construction sector in a single door meeting standard.",
    link: "/business/construction",
  },
  {
    id: 3,
    icon: bankingIcon,
    title: "Banking",
    description:
      "Tater Group is partnered with different companies and business firms to address complex challenges and mobilise resources. Our partners have been contributing ideas and support for our growth and development which is not limited to financial help.",
    link: "/business/banking",
  },
  {
    id: 4,
    icon: tradingIcon,
    title: "Trading",
    description:
      "Tater Group has been representing various world renowned companies in Nepal since the last five decades. The major service is trading of construction materials and power equipments within and across the borders.",
    link: "/business/trading",
  },
  {
    id: 5,
    icon: energyIcon,
    title: "Energy",
    description:
      "Tater Group has been involved in various hydropower projects of Nepal.",
    link: "/business/energy",
  },
  {
    id: 6,
    icon: technologyIcon,
    title: "IT & Education",
    description:
      "Tater Group is working in IT and education sector to digitize education system of Nepal and provide IT services to the clients.",
    link: "/business/it_edu",
  },
];
