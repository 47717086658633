import React from "react";
import { Link } from "react-router-dom";
interface Button {
  text: string;
  link?: string;
}

const SecondaryButton = ({ text, link }: Button) => {
  const contains = link?.includes("https" || "http");
  if (!link) {
    return (
      <button
        type="submit"
        className="py-4 px-5 rounded-full bg-gradient-to-r from-gradient-1 to-gradient-2 text-white font-primary text-sm font-semibold shadow-md transition-all ease-in-out duration-300 hover:shadow-xl transform hover:-translate-y-1"
      >
        {text}
      </button>
    );
  } else {
    return (
      <div className="py-4">
        {contains ? (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="py-4 px-5 rounded-full bg-gradient-to-r from-gradient-1 to-gradient-2 text-white font-primary text-sm font-semibold shadow-md hover:shadow-xl transition-all ease-in-out duration-300 transform hover:-translate-y-1"
          >
            {text}
          </a>
        ) : (
          <Link
            to={link}
            className="py-4 px-5 rounded-full bg-gradient-to-r from-gradient-1 to-gradient-2 text-white font-primary text-sm font-semibold shadow-md transition-all ease-in-out duration-300 hover:shadow-xl transform hover:-translate-y-1"
          >
            {text}
          </Link>
        )}
      </div>
    );
  }
};

export default SecondaryButton;
