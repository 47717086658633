import React from "react";
import { TradingBanner, TradingSectors } from "../../../components";

const Trading = () => {
  return (
    <div>
      <TradingBanner />
      <TradingSectors />
    </div>
  );
};

export default Trading;
