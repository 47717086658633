import React from "react";

interface testimonialCardProps {
  name: string;
  image: string;
  description: string;
}

const TestimonialCard = ({
  name,
  image,
  description,
}: testimonialCardProps) => {
  return (
    <div className="flex flex-col justify-between bg-white rounded-lg shadow-lg px-4 transition-all ease-in-out duration-300 transform hover:shadow-xl hover:-translate-y-2 hover:-rotate-2">
      <p className="font-primary text-sm text-black font-medium leading-relaxed pt-12">
        {description}
      </p>
      <div className="flex gap-5 items-center pt-12 pb-9">
        <img
          src={image}
          alt=""
          className="w-14 h-14 rounded-full object-cover"
        />
        <p className="font-primary text-lg font-semibold text-black capitalize">
          {name}
        </p>
      </div>
    </div>
  );
};

export default TestimonialCard;
