// BLOGS
export const GET_BLOGS = "GET_BLOGS";
export const GET_SINGLE_BLOG = "GET_SINGLE_BLOG";
export const BLOG_ERROR = "BLOG_ERROR";

// Teams
export const GET_TEAMS = "GET_TEAMS";
export const TEAM_ERROR = "TEAM_ERROR";

// Vacancies
export const GET_VACANCIES = "GET_VACANCIES";
export const VACANCIES_ERROR = "VACANCIES_ERROR";

// Associate
export const GET_ASSOCIATE = "GET_ASSOCIATE";
export const ASSOCIATE_ERROR = "ASSOCIATE_ERROR";

// Banner_Swiper
export const GET_BANNER_SWIPER = "GET_BANNER_SWIPER";
export const BANNER_SWIPER_ERROR = "BANNER_SWIPER_ERROR";

// PRODUCTS
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const PRODUCT_ERROR = "PRODUCT_ERROR";

// Product Slider
export const GET_PRODUCT_SLIDER = "GET_PRODUCT_SLIDER";
export const PRODUCT_SLIDER_ERROR = "PRODUCT_SLIDER_ERROR";

// Alert
export const GET_ALERT = "GET_ALERT";
export const ALERT_ERROR = "ALERT_ERROR";

// Career Form
export const SUBMIT_CAREER = "SUBMIT_CAREER";
export const CAREER_ERR = "CAREER_ERR";

// Contact Form
export const SUBMIT_CONTACT = "SUBMIT_CONTACT";
export const CONTACT_ERR = "CONTACT_ERR";
