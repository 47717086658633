import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { SecondaryButton } from "../../../components";
import { DescriptiveCard } from "../../Cards";

interface IProps {
  title: string;
  id: number;
  description: string;
  link: string;
  icon: string;
}

const HomeAccordion = ({ title, id, description, link, icon }: IProps) => {
  return (
    <Accordion allowZeroExpanded className="block">
      <AccordionItem className="pb-3">
        <AccordionItemHeading className="py-2 bg-gradient-to-br from-skin-200 to-skin-100 font-primary text-gray-800 text-xl font-semibold uppercase px-3 rounded-md">
          <AccordionItemButton className="flex justify-between items-center">
            {title}
            <div className="flex justify-center items-center w-10 h-10 rounded-full bg-white shadow-md">
              <img src={icon} alt="" className="w-5 h-5" />
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="bg-skin-50 px-3 py-3 rounded-br-md rounded-bl-md">
          <DescriptiveCard
            width={27.31}
            key={id}
            title={title}
            description={description}
            img=""
          />

          <SecondaryButton text="Learn More" link={link} />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default HomeAccordion;
