// @ts-nocheck
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getSingleBlog } from "../../Redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { BlogSingleSkeleton } from "../../components";

const BlogSingle = ({ match, getSingleBlog, blog, loading }) => {
  useEffect(() => {
    getSingleBlog({ id: match.params.id });
  }, [getSingleBlog, match]);

  return (
    <>
      {loading ? (
        <div className=" container ml-5 ">
          <BlogSingleSkeleton />
        </div>
        
      ) : (
        <div className="container mx-auto pt-10 pb-20 md:py-10">
          <div className="flex justify-between pt-10 items-center">
            <div className="flex items-center">
              {blog?.acf && (
                <>
                <Link
                to="/blog_media"
                className="font-primary text-sm pr-3 text-gray-500"
              >
                Blog
              </Link>
              <i className="fas fa-chevron-right text-gray-400"></i>
                <p className="font-semibold font-primary text-gray-400 pl-3">
                  {blog.acf.blog_title}
                </p>
                </>
              )}
            </div>
          </div>
          <div className="pt-16">
            <div className="flex flex-col pb-8">
              {blog?.acf && (
                <p className="font-primary text-2xl lg:text-4xl text-gray-800 font-semibold capitalize">
                  {blog.acf.blog_title}
                </p>
              )}
              <div className="flex pt-3">
                {blog?.acf && (
                  <p className="font-primary text-sm lg:text-xl text-gray-500">
                  {moment(blog.modified).format("Do MMMM YYYY")}
                </p>
                )}
                <p className="font-primary text-sm lg:text-xl text-primary-900 pl-3 lg:pl-5">
                  {blog?.acf?.company_name}
                </p>
              </div>
            </div>
            {blog?.acf && (
              <img
                src={blog?.acf?.featured_image.url}
                alt=""
                className="w-full rounded-md shadow-md"
              />
            )}
            <div>
              {blog?.acf && (
                <p className="font-primary leading-7 text-base lg:text-lg px-3 lg:px-10 text-justify my-10">
                  {blog?.acf?.blog_description}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  blog: state.blogList.blog,
  loading: state.blogList.loading,
});

export default connect(mapStateToProps, { getSingleBlog })(BlogSingle);
